<template>
    <div>
        <b-modal
            id="modal-add-booking-tour"
            size="lg"
            centered
            hide-header
            :no-close-on-backdrop="true"
            v-model="isOpen"
        >
        <b-card-header class="banned-background-color-primary">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 class="mb-0 text-primary">
              {{ $t('golf_tour_add_booking_tour') }}
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              color="#114A9F"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
            <!-- <b-row>
                  <b-col cols="4">
                    <b-form-group :label="$t('golf_tour_flight_search_date')">
                      <date-range-picker
                        ref="picker"
                        v-model="chooseDate"
                        :single-date-picker="true"
                        opens="right"
                        :ranges="false"
                        :time-picker="false"
                        :time-picker-increment="1"
                        :show-dropdowns="true"
                        :auto-apply="true"
                        :locale-data="{
                          format: 'DD/MM/YYYY',
                          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                          firstDay: 0
                        }"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="8">
                    <b-form-group :label="$t('golf_tour_flight_search_course')">
                      <v-select
                        v-model="selectedCourse"
                        label="CourseName"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="listCourse"
                        :multiple="true"
                        :clearable="false"
                      >
                        <template #selected-option="{ CourseName, Color }">
                          <ez-icon
                            icon="ezGolf-icon-golf-course"
                            :color="Color"
                            :size="18"
                          />
                          {{ CourseName }}
                        </template>
                        <template v-slot:option="item">
                          <ez-icon
                            icon="ezGolf-icon-golf-course"
                            :size="18"
                            :color="item.Color"
                          />
                          {{ item.CourseName }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row> -->
                <b-row>
                    <b-col cols="12">
                        <b-form-group :label="$t('golf_tour_flight_select_rate')">
                            <v-select v-model="Selected_Rate_RRA04A"
                                label="RRA04RateName" :options="ListRate_RRA04A"
                                placeholder="Tìm kiếm rate: GolfClass#Công ty#Nguồn" :clearable="false"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
        </b-card-body>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="checkMember"
                    :disabled="disableButton"
                > 
                  <b-spinner
                      v-if="disableButton"
                      small
                      label="Loading..."
                    />
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                  size="md"
                  variant="none"
                  class="float-right mr-1"
                  @click="hideModal"
                  :disabled="disableButton"
                  >
                      {{ $t('golf_common_back') }}
                </b-button>
            
            </div>
        </template>
        </b-modal>
    </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { courseSetting } from '@/api/course-setting'
import { booking } from '@/api/booking'

import { tour } from '@/api/tour'
import { members } from '@/api/members'
export default {
    components: {
        Treeselect
    },
    data() {
        return {
            isOpen: false,
            listMember: [],
            MemberSelect: [],
            listCourse: [],
            selectedCourse: [],
            chooseDate: null,
            ListRate_RRA04A: [],
            Selected_Rate_RRA04A: null,
            disableButton: false
        }
    },
    props: ['tourId', 'data', 'dataFight', 'dateAddBookingProps'],
    created() {
        this.getListCourse()
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-add-booking-tour')
        },
        async getListRate() {
            const body = {
                Date: this.chooseDate,
                Course: this.data.Courses,
            }
            await booking.api_RRA04A(body).then(res => {
                this.ListRate_RRA04A = res.Data.Rate
            })
        },
        async getListCourse() {
            const response = await courseSetting.getListCourse()
            if(response.Status == 200) {
                this.listCourse = response?.Data?.Course 
                this.selectedCourse = response?.Data?.Course 
            }
        },
        async checkMember() {
          if(!this.Selected_Rate_RRA04A) {
            return this.showToast('error', this.$t('golf_tour_please_choose_rate_code'))
          }
          this.disableButton = true
          const data = []
          this.dataFight.forEach(x => {
            if(x.TournamentMember.length > 0) {
              data.push({
                TournamentMemberId: x.TournamentMember.map(mem => mem.Id),
                GolfClassId: this.Selected_Rate_RRA04A.GolfClassId,
                RateId: this.Selected_Rate_RRA04A.Id,
                ClientId: this.Selected_Rate_RRA04A.ClientId,
                SourceId: this.Selected_Rate_RRA04A.SourceId,
                Line: x.Line
              })
            }
            
          })
          const bodyIBK01 = {
            TournamentId: this.tourId,
            BookingTournamentMember: data,
            IsNewBooking: true,
            BookingId: null 
          }
          const dataMember = []
          this.dataFight.forEach(flight => {
            flight.TournamentMember.filter(z => !z.BookingDetailId).forEach(x => {
              dataMember.push({
                TournamentMemberId: x.Id,
                VgaCode: x.VgaCode,
                FullName: x.FullName
              })
            })
          })
          const body = {
            TournamentMember: dataMember
          }
          await members.CheckMember_RME07(body).then(res => {
            if(res.Status == 200) {
              if(res.Data.TournamentMember.length > 0) {
                this.disableButton = false
                this.$emit('event', { type: 'after-check-duplicate-vga' , DataMember: res.Data.TournamentMember , DataIBK01: bodyIBK01 })
                this.hideModal()
              } else {
                this.save(bodyIBK01)
              }
            } else {
              this.disableButton = false
            }
          })
        },
        async save(body) {
          await tour.api_TOUR_IBK01(body).then(async res => {
          await this.updateBagtagLocker(body)
          this.disableButton = false
            this.showResToast(res)
            if(res.Status == 200) {
                this.$emit('event', { type: 'after-add-booking' })
                this.hideModal()
            }
          })
        },
        async updateBagtagLocker(data) {
          var tourMemberId = []
          data.BookingTournamentMember.forEach(x => {
            tourMemberId = [...tourMemberId, ...x.TournamentMemberId]
          })
          await tour.api_TOUR_UBK10({
            TournamentMemberIdBagTagLocker: tourMemberId,
            TournamentMemberIdMember: []
          })
        }
    },
    watch: {
        isOpen(value) {
            if(value) {
                console.log(this.dataFight)
                this.chooseDate = this.dateAddBookingProps
                this.getListRate()
            } else {
              this.Selected_Rate_RRA04A = null
            }
        }
    }
}
</script>
<style lang="scss">
    
</style>