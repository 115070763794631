<template>
    <div>
        <b-modal
            id="modal-select-member-add-booking-tour"
            size="xl"
            centered
            hide-header
            :no-close-on-backdrop="true"
            v-model="isOpen"
        >
        <b-card-header class="banned-background-color-primary">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 class="mb-0 text-primary">
              {{ $t('golf_tour_add_booking_tour') }}
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              color="#114A9F"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
          <div>
            <b-row v-for="(item, index) in data.DataMember" :key="index + 'member'">
              <b-col cols="2"  class="form-group">
                <label class="font-weight-bold">{{ $t('golf_tour_flight_vga_code') }}</label>
                <b-form-group>
                  <span :style="item.Type == 'NO_VGA' ? 'text-decoration:line-through;' : ''">
                    {{ item.VgaCode }}
                  </span>
                </b-form-group>
              </b-col>
              <b-col cols="2" class="form-group">
                <label class="font-weight-bold">{{ $t('golf_tour_flight_fullname_in_tour') }}</label>
                <b-form-group>
                  <span :style="item.Type == 'USE_VGA' ? 'text-decoration:line-through;' : ''">
                    {{ item.FullName }}
                  </span>
                </b-form-group>
              </b-col>
              <b-col cols="2" class="form-group">
                <label class="font-weight-bold">{{ $t('golf_tour_flight_fullname_in_member') }}</label>
                <b-form-group>
                  <span :style="item.Type == 'NO_VGA' ? 'text-decoration:line-through;' : ''">
                    {{ item.FullNameInDB }}
                  </span>
                </b-form-group>
              </b-col>
              <b-col cols="6" class="form-group">
                <label class="font-weight-bold">{{ $t('golf_tour_flight_type_add_booking') }}</label>
                <b-form-group>
                  <b-form-radio-group
                    button-variant="outline-primary"
                    class="sex-list"
                    v-model="item.Type"
                    @change="changeType"
                  >
                    <b-form-radio
                      v-for="option in opsionType"
                      :key="option.label"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
          </b-row>
          </div>
        </b-card-body>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="save"
                    :disabled="disableButton"
                > 
                  <b-spinner
                      v-if="disableButton"
                      small
                      label="Loading..."
                    />
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                  size="md"
                  variant="none"
                  class="float-right mr-1"
                  @click="hideModal"
                  :disabled="disableButton"
                  >
                      {{ $t('golf_common_back') }}
                </b-button>
            
            </div>
        </template>
        </b-modal>
    </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { courseSetting } from '@/api/course-setting'
import { booking } from '@/api/booking'

import { tour } from '@/api/tour'
import { members } from '@/api/members'
export default {
    components: {
        Treeselect
    },
    data() {
        return {
            isOpen: false,
            disableButton: false,
            opsionType: [
              {
                label: this.$t('golf_tour_add_member_no_vga_code'),
                value: 'NO_VGA'
              }, 
              {
                label: this.$t('golf_tour_add_member_use_vga_code'),
                value: 'USE_VGA'
              }
            ],
            listMember: []
        }
    },
    props: ['data','tourId'],
    created() {
      this.getListGolfer()
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-select-member-add-booking-tour')
        },
        async save() {
          this.disableButton = true
          const dataMemberNoVGA = this.data.DataMember.filter(x => x.Type == 'NO_VGA').map(z => z.TournamentMemberId)
          const dataMemberUseVGA = this.data.DataMember.filter(x => x.Type == 'USE_VGA')
          if(dataMemberUseVGA.length > 0) {
            for(let i = 0; i < dataMemberUseVGA.length; i++) {
              const body = {
                TournamentId: this.tourId,
                TournamentMember: this.listMember.find(x => x.Id == dataMemberUseVGA[i].TournamentMemberId)
              }
              body.TournamentMember.FullName = dataMemberUseVGA[i].FullNameInDB
              body.TournamentMember.MemberId = dataMemberUseVGA[i].MemberId
              await tour.api_TOUR_UGO01(body)
              if(i == dataMemberUseVGA.length - 1) {
                if(dataMemberNoVGA.length > 0) {
                  const bodyUpdateMember = {
                    TournamentMemberId: dataMemberNoVGA,
                    CreateBookingWithoutVGA:true,
                  }
                  await tour.api_TOUR_UGO02(bodyUpdateMember)
                }
                await tour.api_TOUR_IBK01(this.data.DataIBK01).then(async res => {
                  await this.updateBagtagLocker(this.data.DataIBK01)
                  this.showResToast(res)
                  this.disableButton = false
                  if(res.Status == 200) {
                      this.$emit('event', { type: 'after-add-booking' })
                      this.hideModal()
                  }
                })
              }
            }
          } else if(dataMemberNoVGA.length > 0) {
            const bodyUpdateMember = {
                    TournamentMemberId: dataMemberNoVGA,
                    CreateBookingWithoutVGA:true,
                  }
            await tour.api_TOUR_UGO02(bodyUpdateMember)
            await tour.api_TOUR_IBK01(this.data.DataIBK01).then(async res => {
              await this.updateBagtagLocker(this.data.DataIBK01)
              this.showResToast(res)
              this.disableButton = false
              if(res.Status == 200) {
                  this.$emit('event', { type: 'after-add-booking' })
                  this.hideModal()
              }
            })
          }
        },
        changeType() {
          this.$forceUpdate()
        },
        async getListGolfer() {
          const body = {
            TournamentId: this.tourId,
            TournamentGroupId: [],
            TournamentFlightId: [],
            VgaCode: [],
            KeyWord: null,
            isGetStoke: false
          }
          await tour.api_TOUR_RGO01(body).then(res => {
            if(res.Status == 200) {
              this.listMember = res.Data.TournamentMember
            }
          })
        },
        async updateBagtagLocker(data) {
          var tourMemberId = []
          data.BookingTournamentMember.forEach(x => {
            tourMemberId = [...tourMemberId, ...x.TournamentMemberId]
          })
          await tour.api_TOUR_UBK10({
            TournamentMemberIdBagTagLocker: tourMemberId,
            TournamentMemberIdMember: []
          })
        }
    },
    watch: {
        isOpen(value) {
            if(value) {
                this.data.DataMember.forEach(x => {
                  x.Type = 'NO_VGA'
                })
            } else {
            }
        }
    }
}
</script>
<style lang="scss">
    
</style>