<template>
    <b-card>
        <b-row class="pb-1">
            <b-col align="right">
                <b-button
                    variant="outline-primary"
                    @click="autoDivedeGroup"
                >
                    <ez-icon
                        icon="ezGolf-icon-sidebar"
                        class="m-50"
                    />
                    <span class="align-middle">{{ $t('golf_tour_add_league_auto') }}</span>
                </b-button>
                <b-button
                    variant="outline-primary"
                    @click="addGroupTour"
                    class="ml-1"
                >
                    <ez-icon
                        icon="ezGolf-icon-plus-circle"
                        class="mr-50"
                    />
                    <span class="align-middle">{{ $t('golf_tour_add_league') }}</span>
                </b-button>
                
            </b-col>
        </b-row>
        <b-row class=" border-top">
            <b-col cols="4" v-for="item in listGroup" :key="item" class="mt-1">
                <div class="m-1" style="border: 1px solid rgba(17, 74, 159, 1); border-radius: 6px;height: 100%;">
                    <h4 class="d-flex justify-content-between pt-1 pl-1 pr-1">
                        <span>
                            {{ item.GroupName }}
                        </span>
                        <b-nav class="icon-action-locker">
                            <b-nav-item-dropdown
                            id="my-nav-dropdown"
                            right
                            >
                            <template slot="button-content">
                                <feather-icon
                                icon="MoreVerticalIcon"
                                color="#114A9F"
                                />
                            </template>
                            <b-dropdown-item
                                type="button"
                                @click="viewDetailGroup(item)"
                            >{{ $t('golf_common_view_detail') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                type="button"
                                @click="addGolferDetailGroup(item)"
                            >{{ $t('golf_tour_add_golfer_detail') }}
                            </b-dropdown-item>
                            <b-dropdown-divider />
                            <b-dropdown-item>
                                <div
                                    style="color: #EA5455"
                                    type="button"
                                    @click="deleteGroup(item.Id)"
                                
                                    >
                                    {{ $t('golf_common_delete') }}
                                </div>
                            </b-dropdown-item>
                            </b-nav-item-dropdown>
                        </b-nav>
                    </h4>
                    <b-table class="table-group-tour" :items="item.TournamentMember" :fields="fieldsTableGroup">
                        <template #cell(action)="data">
                            <ez-icon
                                icon="ezGolf-icon-trash"
                                class="mr-50"
                                @click="deleteGolfer(data.item)"
                                color="#EA5455"
                                size="18"
                            />
                        </template>
                    </b-table>
                </div>
                
            </b-col>
        </b-row>
        <modalAddGroup :tourId="tourId" @event="handleEvent" :data="dataProps" />
        <modalSelectGolfer :tourId="tourId" :data="dataPropsSelectGolfer" @event="handleEvent" />
    </b-card>
</template>
<script>
import modalAddGroup from './modal/modalAddGroup.vue'
import modalSelectGolfer from './modal/modalSelectGolfer.vue'
import { tour } from '@/api/tour.js'
export default {
    props: ['tourId'],
    components: {
        modalAddGroup,
        modalSelectGolfer
    },
    data() {
        return {
            listGroup: null,
            fieldsTableGroup: [
                {
                    key: 'Vgacode',
                    label: 'Mã Vga',
                },
                {
                    key: 'FullName',
                    label: 'Họ tên',
                },
                {
                    key: 'HandicapIndex',
                    label: 'HDC index',
                },
                {
                    key: 'action',
                    label: '',
                }
            ],
            dataProps: {
                type: null,
                data: null  
            },
            dataPropsSelectGolfer: null 
        }
    },
    methods: {
        openModalAddLeague() {
            this.$bvModal.show('modal-tour-add-group')
        },
        addGroupTour() {
            this.dataProps = {
                type: 'ADD',
                data: null
            }
            this.openModalAddLeague()
        },
        async getListGroup() {
            const body = {
                TournamentId: this.tourId,
                TournamentGroupId: null,
                GroupName: null,
                IsGetPlayer: true
            }
            await tour.api_TOUR_RGR01(body).then(res => {
                if(res.Status == 200) {
                    this.listGroup = res.Data.TournamentGroup
                }
            })
        },
        async deleteGroup(data) {
            const body = {
                TournamentGroupId: [data]
            }
            await tour.api_TOUR_DGR01(body).then(res => {
                this.showResToast(res)
                if(res.Status == 200) {
                    this.getListGroup()
                }
            })
        },
        viewDetailGroup(data) {
            this.dataProps = {
                type: 'DETAIL',
                data: data
            }
            this.openModalAddLeague()
        },
        handleEvent(result) {
            if (result.type === 'after-create-group') {
                this.getListGroup()
            }
            if (result.type === 'after-add-golfer') {
                this.getListGroup()
            }
        },
        async autoDivedeGroup() {
            const body = {
                TournamentId: this.tourId
            }
            await tour.api_TOUR_UGR04(body).then(res => {
                this.showResToast(res)
                if(res.Status == 200) {
                    this.getListGroup()
                }
            })
        },
        addGolferDetailGroup(item) {
            this.dataPropsSelectGolfer = item
            this.$bvModal.show('modal-add-golfer-group')
        },
        async deleteGolfer(data) {
            const body = {
                TournamentMemberId: [data.Id]
            }
            await tour.api_TOUR_UGR03(body).then(res => {
                this.showResToast(res)
                if(res.Status == 200) {
                    this.getListGroup()
                }
            })
        }
    },
    mounted() {
        this.getListGroup()
    }
}
</script>
<style lang="scss">
.dropdown-toggle::after {
        display: none !important;
    }
.table-group-tour {
    thead > tr > th {
        background-color: #fff !important;
    }
    
}
</style>