<template>
    <div>
        <b-modal
            id="modal-tour-add-group"
            size="sm"
            centered
            hide-header
            :no-close-on-backdrop="true"
            v-model="isOpen"
        >
        <b-card-header class="banned-background-color-primary">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 v-if="data.type == 'ADD'" class="mb-0 text-primary">
              {{ $t('golf_tour_add_group_detail') }}
            </h4>
            <h4 v-else class="mb-0 text-primary">
              {{ $t('golf_tour_update_group_detail') }}
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              color="#114A9F"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
            <validation-observer
                ref="addGroup"
                tag="div"
            >
                <b-form
                    class="detail-caddy-group"
                    @submit.stop.prevent="handleSubmit"
                >
                    <b-form-group :label="$t('golf_tour_group_name')" class="label-requires">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_tour_group_name')"
                            rules="required|max:50"
                            >
                            <b-form-input
                                :placeholder="$t('golf_tour_add_group_group_name')"
                                :state="errors.length > 0 ? false : null"
                                v-model="dataAddGroup.GroupName"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('golf_tour_add_group_sex')" class="label-requires">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_tour_add_group_sex')"
                            rules="required"
                            >
                                <v-select
                                    label="KeyValue"
                                    :class="(errors.length > 0) ? 'v-select-error' : ''"
                                    :state="errors.length > 0 ? false : null"
                                    :options="listSexCode"
                                    :reduce="item => item.KeyCode"
                                    :multiple="true"
                                    :placeholder="$t('golf_tour_add_group_sex')"
                                    v-model="dataAddGroup.Gender"
                                />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    
                    <b-form-group :label="$t('golf_tour_add_group_age')" >
                        <div class="d-flex justify-content-between pl-1 pr-1">
                            <b-form-input
                                style="width: 48%;"
                                :placeholder="$t('golf_tour_add_group_from_age')"
                                type="number"
                                v-model="dataAddGroup.FromAge"
                            />
                            <b-form-input
                                style="width: 48%;"
                                :placeholder="$t('golf_tour_add_group_to_age')"
                                type="number"
                                v-model="dataAddGroup.ToAge"
                            />
                        </div>
                    </b-form-group>
                    
                    <b-form-group :label="$t('golf_tour_add_group_hadicapindex')" >
                        <div class="d-flex justify-content-between pl-1 pr-1">
                            <b-form-input
                                style="width: 48%;"
                                :placeholder="$t('golf_tour_add_group_from_hadicapindex')"
                                type="number"
                                v-model="dataAddGroup.FromHadicapIndex"
                            />
                            <b-form-input
                                style="width: 48%;"
                                :placeholder="$t('golf_tour_add_group_to_hadicapindex')"
                                type="number"
                                v-model="dataAddGroup.ToHadicapIndex"
                            />
                        </div>
                    </b-form-group>

                    <b-form-group :label="$t('golf_tour_add_group_hadicapcourse')" class="label-requires">
                        <div class="d-flex justify-content-between pl-1 pr-1">
                            <validation-provider
                                style="width: 48%;"
                                #default="{ errors }"
                                :name="$t('golf_tour_add_group_from_hadicapcourse')"
                                rules="required"
                                >
                                <b-form-input
                                    :placeholder="$t('golf_tour_add_group_from_hadicapcourse')"
                                    :state="errors.length > 0 ? false : null"
                                    type="number"
                                    v-model="dataAddGroup.FromHadicapCourse"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <validation-provider
                                style="width: 48%;"
                                #default="{ errors }"
                                :name="$t('golf_tour_add_group_to_hadicapcourse')"
                                rules="required"
                                >
                                <b-form-input
                                    :placeholder="$t('golf_tour_add_group_to_hadicapcourse')"
                                    :state="errors.length > 0 ? false : null"
                                    type="number"
                                    v-model="dataAddGroup.ToHadicapCourse"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </div>
                    </b-form-group>
                </b-form>
            </validation-observer>
        </b-card-body>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="saveAddGroup"
                    v-if="data.type == 'ADD'"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                    v-else
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="UpdateAddGroup"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                size="md"
                variant="none"
                class="float-right mr-1"
                >
                    {{ $t('golf_common_back') }}
                </b-button>
            
            </div>
        </template>
        </b-modal>
    </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'
import { commonServices } from '@/api/common-services'
import { tour } from '@/api/tour'
export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    props: ['tourId', 'data'],
    data() {
        return {
            dataAddGroup: {
                GroupName: null,
                Gender: null,
                FromAge: null,
                ToAge: null,
                FromHadicapIndex: null,
                ToHadicapIndex: null,
                FromHadicapCourse: null,
                ToHadicapCourse: null,
            },
            currentLang: this.$i18n.locale,
            listSexCode: [],
            isOpen: false
        }
    },
    watch: {
        isOpen(value) {
            if(value) {
                if(this.data.type == 'DETAIL') {
                    this.dataAddGroup = {
                        GroupName: this.data.data.GroupName,
                        Gender: this.data.data.Gender,
                        FromAge: this.data.data.FromAge,
                        ToAge: this.data.data.ToAge,
                        FromHadicapIndex: this.data.data.FromHadicapIndex,
                        ToHadicapIndex: this.data.data.ToHadicapIndex,
                        FromHadicapCourse: this.data.data.FromHadicapCourse,
                        ToHadicapCourse: this.data.data.ToHadicapCourse,
                    }
                }
            } else {
                this.clearData()
            }
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-tour-add-group')
        },
        async saveAddGroup() {
            this.$refs.addGroup.validate().then(async success => {
                if(success) {
                   const body = {
                        Group: {
                            GroupName: this.dataAddGroup.GroupName,
                            Gender: this.dataAddGroup.Gender,
                            FromAge: this.dataAddGroup.FromAge,
                            ToAge: this.dataAddGroup.ToAge,
                            FromHadicapIndex: this.dataAddGroup.FromHadicapIndex,
                            ToHadicapIndex: this.dataAddGroup.ToHadicapIndex,
                            FromHadicapCourse: Number(this.dataAddGroup.FromHadicapCourse),
                            ToHadicapCourse: Number(this.dataAddGroup.ToHadicapCourse),
                            TournamentId: this.tourId
                        }
                   }
                   await tour.api_TOUR_IGR01(body).then(res => {
                        this.showResToast(res)
                        if(res.Status == 200) {
                            this.$emit('event', { type: 'after-create-group' })
                            this.hideModal()
                        }
                   })
                }
            })
        },
        async UpdateAddGroup() {
            this.$refs.addGroup.validate().then(async success => {
                if(success) {
                   const body = {
                        Group: {
                            Id: this.data.data.Id,
                            GroupName: this.dataAddGroup.GroupName,
                            Gender: this.dataAddGroup.Gender,
                            FromAge: this.dataAddGroup.FromAge,
                            ToAge: this.dataAddGroup.ToAge,
                            FromHadicapIndex: this.dataAddGroup.FromHadicapIndex,
                            ToHadicapIndex: this.dataAddGroup.ToHadicapIndex,
                            FromHadicapCourse: Number(this.dataAddGroup.FromHadicapCourse),
                            ToHadicapCourse: Number(this.dataAddGroup.ToHadicapCourse),
                            TournamentId: this.tourId
                        }
                   }
                   await tour.api_TOUR_UGR01(body).then(res => {
                        this.showResToast(res)
                        if(res.Status == 200) {
                            this.$emit('event', { type: 'after-create-group' })
                            this.hideModal()
                        }
                   })
                }
            })
        },
        clearData() {
            this. dataAddGroup = {
                GroupName: null,
                Gender: null,
                FromAge: null,
                ToAge: null,
                FromHadicapIndex: null,
                ToHadicapIndex: null,
                FromHadicapCourse: null,
                ToHadicapCourse: null
            }
        },
        async getListSexCode() {
            await commonServices
            .getCommon({ KeyGroup: 'SEX' })
            .then(res => {
            if (res) {
                this.listSexCode = res.Data.filter(x => x.LangId == this.currentLang)
            }
            })
        },
    },
    created() {
        this.getListSexCode()
    },
}
</script>
<style lang="scss">
 .banned-background-color-primary {
      background: rgba(17, 74, 159, 0.12);
}
</style>