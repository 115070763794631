<template>
    <b-card>
        <b-row>
            <!-- <b-col cols="4" style="border-right: 1px solid rgba(233, 235, 241, 1);">
                <b-row v-for="(item, index) in listGroup" :key="index + 'gr'" class="mt-1">
                    <b-col cols="12">
                        <h3>
                            {{ item.GroupName }}
                        </h3>
                    </b-col>
                    <b-col cols="12">
                        <span class="item-golfer" v-for="(item2, index2) in item.TournamentMember" :key="index2 + 'pp'">
                            {{ item2.FullName }}
                        </span>
                    </b-col>
                </b-row>
            </b-col> -->
            <b-col cols="12">
                <b-row class="mb-1">
                    <b-col cols="12" class="d-flex justify-content-end">
                        <b-button
                            variant="outline-primary"
                            @click="addFlight"
                            class="ml-1"
                        >
                            <ez-icon
                                icon="ezGolf-icon-plus-circle"
                                class="mr-50"
                            />
                            <span class="align-middle">{{ $t('golf_tour_add_flight') }}</span>
                        </b-button>
                        <b-button
                            variant="outline-primary"
                            @click="selectAllGolfer"
                            class="ml-1"
                        >
                            <span class="align-middle">{{ $t('golf_tour_select_all') }}</span>
                        </b-button>
                        <b-button
                            variant="outline-primary"
                            @click="addBooking"
                            class="ml-1"
                        >
                            
                            <span class="align-middle">Tạo booking</span>
                        </b-button>
                    </b-col>
                </b-row>
                <b-row style="max-height: calc(100vh - 200px);overflow: auto;">
                    <b-col v-for="(item, index) in listFight" cols="6" :key="index + 'fight'" class="mt-1">
                        <div style="border: 1px dashed rgba(214, 217, 225, 1);border-radius: 5px; padding: 10px;">
                            <div class="d-flex justify-content-between">
                                <span style="font-size: 15px;font-weight: 500;line-height: 18px;">
                                    {{ item.FlightName }}
                                </span>
                                <span style="font-size: 15px;font-weight: 500;line-height: 18px;" class="d-flex">
                                    <span class="mt-auto mb-auto" v-if="item.OpenDateTime">
                                        <ez-icon
                                        icon="ezGolf-icon-clock"
                                        class="mr-50"
                                    />
                                    {{ convertUTC(item.OpenDateTime, 'DD/MM/YYYY HH:mm') }} - {{ item.TeeName }}
                                    </span>
                                    <b-nav>
                                        <b-nav-item-dropdown
                                            id="dropdown"
                                            right
                                        >
                                            <template slot="button-content">
                                            <feather-icon
                                                icon="MoreVerticalIcon"
                                                color="#114A9F"
                                            />
                                            </template>
                                            <b-dropdown-item
                                            type="button"
                                            @click.stop="ViewDetailFight(item)"
                                            >
                                            {{ $t('golf_update_info_fight') }}
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                type="button"
                                                @click.stop="AddGolferFight(item)"
                                            >
                                            {{ $t('golf_add_golfer_to_fight') }}
                                            </b-dropdown-item>
                                            <b-dropdown-divider />
                                            <b-dropdown-item>
                                                <div
                                                    style="color: #EA5455"
                                                    type="button"
                                                    @click="$refs.deleteDialog.open({
                                                        Id : item.Id,
                                                    })"
                                                    >
                                                    {{ $t('golf_common_delete') }}
                                                </div>
                                            </b-dropdown-item>
                                        </b-nav-item-dropdown>
                                        </b-nav>
                                </span>
                            </div>
                            <DxDataGrid
                                :id="'table-tour' + index"
                                :show-borders="false"
                                :data-source="item.TournamentMember"
                                :show-column-lines="true"
                                :show-row-lines="true"
                                :allow-column-reordering="true"
                                :allow-column-resizing="true"
                                column-resizing-mode="widget"
                            >
                                <DxColumn
                                    :caption="''"
                                    :data-field="'check'"
                                    :cell-template="'check'"
                                    :header-cell-template="'headerCheck'"
                                    :alignment="'center'"
                                    :width="'10%'"
                                    :aria-sort="true"
                                />
                                <DxColumn
                                v-for="(item, i) in fieldsTableGroup"
                                :key="i.field"
                                :caption="item.caption"
                                :data-field="item.field"
                                :data-type="item.type"
                                :alignment="item.alignment"
                                :cell-template="(item.cellTemplate) ? item.cellTemplate : false"
                                :width="item.width"
                                >
                                <DxColumn
                                    v-for="(band) in item.bands"
                                    :key="band.field"
                                    :caption="band.caption"
                                    :data-field="band.field"
                                    :data-type="band.type"
                                    :group-cell-template="customText"
                                    :cell-template="(band.cellTemplate) ? band.cellTemplate : false"
                                    :alignment="band.alignment"
                                    :width="item.width"
                                />
                                </DxColumn>
                                <template #headerCheck="{ data }">
                                    <div
                                        class="d-flex"
                                        style="line-height: 24px"
                                    >
                                        <b-form-checkbox v-model="item.checkAll" @change="changeSelectAll(item.checkAll, item.TournamentMember)" />
                                    </div>
                                    </template>

                                    <template #check="{ data }">
                                    <div class="checkbox-center">
                                        <b-form-checkbox
                                             v-model="data.data.check"
                                        />
                                    </div>
                                    </template>
                                <template #action="{ data }">
                                    <div>
                                        <ez-icon
                                            v-if="!data.data.BookingDetailId"
                                            icon="ezGolf-icon-trash"
                                            class="mr-50"
                                            @click="deleteGolfer(item, data.data)"
                                            color="#EA5455"
                                            size="18"
                                        />
                                    </div>
                                </template>
                            </DxDataGrid>
                            <!-- <b-table class="table-group-tour" :items="item.TournamentMember" :fields="fieldsTableGroup">
                                <template #cell(action)="data">
                                    <ez-icon
                                        v-if="!data.data.BookingDetailId"
                                        icon="ezGolf-icon-trash"
                                        class="mr-50"
                                        @click="deleteGolfer(item, data.data)"
                                        color="#EA5455"
                                        size="18"
                                    />
                                </template>
                            </b-table> -->
                        </div>
                        
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <delete-dialog ref="deleteDialog" :data-remove="{
            title: $t('golf_delete_fight'),
            content: $t('golf_confirm_delete_fight')
        }" @deleteItem="deleteFight" />
        <modalAddFlight :tourId="tourId" @event="handleEvent" :data="dataProps" />
        <modalSelectGolferToFight :tourId="tourId" :data="dataPropsSelectGolfer" @event="handleEvent" />
        <AddBookingTour :tourId="tourId" :data="data" :dataFight="listFightAddBooking" :dateAddBookingProps="dateAddBookingProps" @event="handleEvent"/>
        <SelectMemberAddBooking :tourId="tourId" :data="dataUpdateMemberProps" @event="handleEvent" />
    </b-card>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
  } from 'devextreme-vue/data-grid'
import 'devextreme/dist/css/dx.light.css'
import AddBookingTour from './modal/AddBookingTour.vue'
import modalSelectGolferToFight from './modal/modalSelectGolferToFight.vue'
import SelectMemberAddBooking from './modal/SelectMemberAddBooking.vue'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import modalAddFlight from './modal/modalAddFlight.vue'
import { tour } from '@/api/tour.js'
import { booking } from '@/api/booking'
export default {
    props: ['tourId', 'data'],
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRequiredRule,
        DxRangeRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport,
        modalAddFlight,
        'delete-dialog': DeleteDialog,
        modalSelectGolferToFight,
        AddBookingTour,
        SelectMemberAddBooking
    },
    data() {
        return {
            listGroup: null,
            dataProps: {
                type: null,
                data: null
            },
            listFight: null,
            dataPropsSelectGolfer: null,
            fieldsTableGroup: [
                {
                  caption: 'Vga',
                  field: 'VgaCode',
                  isShow: true,
                  alignment: 'center',
                  width: '10%',
                },
                {
                  caption: 'Họ tên',
                  field: 'FullName',
                  isShow: true,
                  alignment: 'center',
                  width: '40%',
                },
                {
                  caption: 'HDC',
                  field: 'HDCCourse',
                  isShow: true,
                  alignment: 'center',
                  width: '10%',
                },
                {
                  caption: 'BDC',
                  field: 'BDC',
                  isShow: true,
                  alignment: 'center',
                  width: '20%',
                },
                {
                  caption: '',
                  field: 'action',
                  isShow: true,
                  alignment: 'center',
                  cellTemplate: 'action',
                  width: '10%',
                }
            ],
            defaultLine: null,
            listFightAddBooking: [],
            dateAddBookingProps: null,
            checkAllGolfer: false,
            dataUpdateMemberProps: { type: 'after-check-duplicate-vga' , DataMember: [] , DataIBK01: null }
        }
    },
    methods: {
        async getListGroup() {
            const body = {
                TournamentId: this.tourId,
                TournamentGroupId: null,
                GroupName: null 
            }
            await tour.api_TOUR_RGR01(body).then(res => {
                if(res.Status == 200) {
                    this.listGroup = res.Data.TournamentGroup
                }
            })
        },
        async getListFight() {
            const body = {
                TournamentId: this.tourId,
                IsGetPlayer: true
            }
            await tour.api_TOUR_RTE01(body).then(res => {
                if(res.Status == 200) {
                    res.Data.Flight.forEach(x => {
                        x.Line = this.defaultLine.find(z => z.TeeStart == x.TeeId && z.NumberOfHoles == this.data.Hole) ? this.defaultLine.find(z => z.TeeStart == x.TeeId && z.NumberOfHoles == this.data.Hole).Tee : null
                    });
                    this.listFight = res.Data.Flight
                }
            })
        },
        addFlight() {
            this.dataProps = {
                type: 'ADD',
                data: null
            }
            this.$bvModal.show('modal-tour-add-flight')
        },
        addBooking() {
            const listFightTemp = []
            this.listFight.forEach(x => {
                if(x.TournamentMember.filter(x => x.check).length > 0) {
                    listFightTemp.push(JSON.parse(JSON.stringify(x)))
                }
            })
            listFightTemp.forEach(x => {
                x.TournamentMember = x.TournamentMember.filter(x => x.check)
            })
            this.listFightAddBooking = listFightTemp.filter(x => x.TeeTimeId)
            if(this.listFightAddBooking.length > 0) {
                const DateCheck = this.convertUTC(this.listFightAddBooking[0].OpenDateTime, 'DD/MM/YYYY')
                const offset = this.dayjs().utc().tz(localStorage.getItem('timeZone')).startOf('day').utcOffset()
                const dateAddBooking = this.dayjs(this.listFightAddBooking[0].OpenDateTime).utc().tz(localStorage.getItem('timeZone')).startOf('day')
                    .add(-(offset / 60), 'hour')
                    .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                var check = true
                this.listFightAddBooking.forEach(x => {
                    if(this.convertUTC(x.OpenDateTime, 'DD/MM/YYYY') != DateCheck) {
                        check = false
                    }
                })
                if(check) {
                    this.dateAddBookingProps = dateAddBooking
                    this.$bvModal.show('modal-add-booking-tour')
                } else {
                    this.showToast('error', this.$t('golf_tour_please_add_booking_same_day'))
                }
            } else {
                this.showToast('error', this.$t('golf_tour_please_choose_teetime'))
            }
        },
        handleEvent(result) {
            if (result.type === 'after-add-fight') {
                this.getListFight()
            }
            if (result.type === 'after-add-golfer') {
                this.getListFight()
            }
            if (result.type === 'after-add-booking') {
                this.getListFight()
            }
            if (result.type === 'after-check-duplicate-vga') {
                this.dataUpdateMemberProps = result
                this.$bvModal.show('modal-select-member-add-booking-tour')
            }
        },
        ViewDetailFight(data) {
            this.dataProps = {
                type: 'DETAIL',
                data: data
            }
            this.$bvModal.show('modal-tour-add-flight')
        },
        async AddGolferFight(data) {
            this.dataPropsSelectGolfer = data
            this.$bvModal.show('modal-add-golfer-fight')
        },
        async deleteFight(Id) {
            const body = {
                TournamentFlightId: [Id]
            }
            await tour.api_TOUR_DTE01(body).then(res => {
                this.showResToast(res)
                if(res.Status == 200) {
                    this.getListFight()
                }
            })
        },
        async deleteGolfer(dataFight, dataMember) {
            const body = {
                TournamentFlightId: dataFight.Id,
                TournamentMemberId: [dataMember.Id],
                IsAddFlight: false
            }
            await tour.api_TOUR_UTE01(body).then(res => {
                this.showResToast(res)
                if(res.Status == 200) {
                    this.getListFight()
                }
            })
        },
        async getDefaultLine() {
          await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(res => {
            this.defaultLine = JSON.parse(res?.Data.find(x => x.KeyCode === 'DEFAULT_LINE').KeyValue)
          })
        },
        changeSelectAll(data, dataMember) {
            dataMember.forEach(x => {
                x.check = data
            })
        },
        selectAllGolfer() {
            this.checkAllGolfer = !this.checkAllGolfer
            this.listFight.forEach(x => {
                x.TournamentMember.forEach(y => {
                    y.check = this.checkAllGolfer
                })
            })
            this.$forceUpdate()
        }
    },
    async created() {
        await this.getDefaultLine()
        this.getListGroup()
        this.getListFight()
    }
}
</script>
<style lang="scss">
    .item-golfer {
        padding: 4px 6px; 
        border-radius: 5px;
        background-color: rgba(17, 74, 159, 0.16);
        margin-right: 5px;
        font-weight: 400;
        line-height: 33px;
        font-size: 14px;
        margin-top: 10px;
    }
    .table-group-tour {
        thead > tr > th {
            background-color: #fff !important;
        }
        
    }
</style>