<template>
    <div>
        <b-modal
            id="modal-tour-add-flight"
            size="lg"
            centered
            hide-header
            :no-close-on-backdrop="true"
            v-model="isOpen"
        >
        <b-card-header class="banned-background-color-primary">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 v-if="data.type == 'ADD'" class="mb-0 text-primary">
              {{ $t('golf_tour_add_group_detail') }}
            </h4>
            <h4 v-else class="mb-0 text-primary">
              {{ $t('golf_tour_update_group_detail') }}
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              color="#114A9F"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
            <validation-observer
                ref="addFlight"
                tag="div"
            >
                <b-form
                    class="detail-caddy-group"
                    @submit.stop.prevent="handleSubmit"
                >
                <b-row>
                  <b-col cols="4">
                    <b-form-group :label="$t('golf_tour_flight_name')" class="label-requires">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_tour_group_name')"
                            rules="required|max:50"
                            >
                            <b-form-input
                                :placeholder="$t('golf_tour_add_group_group_name')"
                                :state="errors.length > 0 ? false : null"
                                v-model="dataAddFlight.FlightName"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group :label="$t('golf_tour_flight_search_date')">
                      <date-range-picker
                        ref="picker"
                        v-model="chooseDate"
                        :single-date-picker="true"
                        opens="right"
                        :ranges="false"
                        :time-picker="false"
                        :time-picker-increment="1"
                        :show-dropdowns="true"
                        :auto-apply="true"
                        :locale-data="{
                          format: 'DD/MM/YYYY',
                          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                          firstDay: 0
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group :label="$t('golf_tour_flight_search_course')">
                      <v-select
                        v-model="selectedCourse"
                        label="CourseName"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="listCourse"
                        :multiple="true"
                        style="max-width: 300px"
                        :clearable="false"
                      >
                        <template #selected-option="{ CourseName, Color }">
                          <ez-icon
                            icon="ezGolf-icon-golf-course"
                            :color="Color"
                            :size="18"
                          />
                          {{ CourseName }}
                        </template>
                        <template v-slot:option="item">
                          <ez-icon
                            icon="ezGolf-icon-golf-course"
                            :size="18"
                            :color="item.Color"
                          />
                          {{ item.CourseName }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                </b-form>
            </validation-observer>
            <b-table-simple
              responsive
              bordered
              sticky-header
              class="mt-1"
            >
              <b-thead>
                <b-tr>
                  <b-th
                    v-for="(item, index) in fields"
                    :key="index"
                    class=""
                    style="vertical-align: middle"
                  >
                    <div class="d-flex flex-row align-items-center justify-content-center">
                      {{ item }}
                    </div>
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(item, index) in teeSheet"
                  :key="index"
                >
                  <b-td style="width: 100px">
                    <div class="d-flex flex-row align-items-center justify-content-center">
                      <p class="mb-0 time-label">
                        {{ utcConvertToLocalTimeZone(item.Time, 'HH:mm' ) }}
                      </p>
                    </div>
                  </b-td>
                  <b-td
                    v-for="(tee, indexTeeTime) in item.TeeTime"
                    :key="indexTeeTime"
                    :class="teeTimeSelected.Id && teeTimeSelected.Id === tee.Id ? 'selected-background-color cursor-pointer' :
                       Number(tee.NumberOfGolfer - tee.BookingDetailNumber) === 0 ? 'blocked-background-color cursor-not-allowed' : 'bg-white cursor-pointer'"
                    class="p-0 position-relative"
                    @click="(!tee.IsBlock && !tee.IsDeleted) ? chooseTee(tee) : ''"
                  >
                    <div class="d-flex flex-row align-items-center justify-content-center">
                      <div
                        v-if="!tee.IsBlock && !tee.IsDeleted"
                        class="d-flex justify-content-center align-items-center"
                        :class="tee.Id === teeTimeSelected.Id ? 'text-primary font-weight-bold' : ''"
                      >
                      <div class="text-center">
                          <div class="text-center">
                            <span>{{ tee.NumberOfGolfer - tee.BookingDetailNumber }}</span>
                          </div>
                        </div>
                        <div v-if="tee.Id === teeTimeSelected.Id">
                          <ez-icon
                            icon="ezGolf-icon-check"
                            size="18"
                            class="position-absolute"
                            style="top: 7px; right: 8px;"
                          />
                        </div>
                      </div>
                      <div
                        v-else
                        class="text-center cursor-not-allowed"
                      >
                        <ez-icon
                          :icon="(tee.IsBlock) ? 'ezGolf-icon-lock' : 'ezGolf-icon-slash'"
                          :size="18"
                        />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
        </b-card-body>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    size="md"
                    class="float-right"
                    v-if="data.type == 'ADD'"
                    @click="addFight"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                    v-else
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="updateFight"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                size="md"
                variant="none"
                class="float-right mr-1"
                @click="hideModal"
                >
                    {{ $t('golf_common_back') }}
                </b-button>
            
            </div>
        </template>
        </b-modal>
    </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'
import { commonServices } from '@/api/common-services'
import { tour } from '@/api/tour'
import { booking } from '@/api/booking'
import { courseSetting } from '@/api/course-setting'

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    props: ['tourId', 'data'],
    data() {
        return {
            dataAddFlight: {
                FlightName: null,
                TeeTimeId: null,
                OpenDateTime: null,
                TeeId: null,
                TournamentId: null,
            },
            listCourse: [],
            chooseDate: this.getUTC('start', 'day'),
            selectedCourse: [],
            teeSheet: [],
            fields: [],
            teeTimeSelected: {
              Id: null
            },
            isOpen: false
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-tour-add-flight')
        },
        async getListBooking_RTS01() {
            this.teeTimeSelected = {
              Id: null
            }
            this.teeSheet = []
            this.fields = []
            const ChooseDate = this.chooseDate
            const CourseId = this.selectedCourse.map(x => x.Id)
            const data = {
                Date: String(ChooseDate),
                CourseId,
                Status: ['BOOKED', 'CHECK_IN', 'CHECK_OUT']
            }
            const response = await booking.getListBooking(data)
            if (response.Status === '200') {
                const teeNames = response.Data?.TeeSheet[0].TeeTime.map(tee => (tee.TeeName))
                this.fields = ['TEETIME', ...teeNames]
                this.teeSheet = response?.Data?.TeeSheet
                if(this.data.type == 'DETAIL') {
                  this.teeSheet.forEach(x => {
                    if(x.TeeTime.find(y => y.TeeId == this.data.data.TeeId && y.Id == this.data.data.TeeTimeId)) {
                      this.teeTimeSelected = x.TeeTime.find(y => y.TeeId == this.data.data.TeeId && y.Id == this.data.data.TeeTimeId)
                    }
                  })
                }
            }
        },
        async getListCourse() {
            const response = await courseSetting.getListCourse()
            if(response.Status == 200) {
                this.listCourse = response?.Data?.Course 
                this.selectedCourse = response?.Data?.Course 
            }
        },
        chooseTee(tee) {
          if(tee.NumberOfGolfer - tee.BookingDetailNumber) {
            this.teeTimeSelected = tee
          }
        },
        async addFight() {
          if(this.teeTimeSelected.Id) {
            this.$refs.addFlight.validate().then(async success => {
                if(success) {
                  const body = {
                    Flight: {
                      FlightName: this.dataAddFlight.FlightName,
                      TeeTimeId: this.teeTimeSelected.Id,
                      OpenDateTime: this.teeTimeSelected.OpenDateTime,
                      TeeId: this.teeTimeSelected.TeeId ,
                      TournamentId: this.tourId
                    }
                   
                  }
                  await tour.api_TOUR_ITE01(body).then(res => {
                    this.showResToast(res)
                    if(res.Status == 200) {
                      this.hideModal()
                      this.$emit('event', { type: 'after-add-fight' })
                    }
                  })
                }
            })
          } else {
            this.showToast('error', this.$t('golf_tour_please_select_one_tee_time'))
          }
        },
        updateFight() {
          if(this.teeTimeSelected.Id) {
            this.$refs.addFlight.validate().then(async success => {
                if(success) {
                  const body = {
                    Flight: {
                      Id: this.data.data.Id,
                      FlightName: this.dataAddFlight.FlightName,
                      TeeTimeId: this.teeTimeSelected.Id,
                      OpenDateTime: this.teeTimeSelected.OpenDateTime,
                      TeeId: this.teeTimeSelected.TeeId ,
                      TournamentId: this.tourId
                    }
                   
                  }
                  await tour.api_TOUR_UTE02(body).then(res => {
                    this.showResToast(res)
                    if(res.Status == 200) {
                      this.hideModal()
                      this.$emit('event', { type: 'after-add-fight' })
                    }
                  })
                }
            })
          } else {
            this.showToast('error', this.$t('golf_tour_please_select_one_tee_time'))
          }
        }
    },
    async created() {
        
    },
    watch: {
      chooseDate() {
        this.getListBooking_RTS01()
      },
      selectedCourse() {
        this.getListBooking_RTS01()
      },
      async isOpen(value) {
        if(value) {
          if(this.data.type == 'ADD') {
              this.chooseDate = this.getUTC('start', 'day')
              this.dataAddFlight = {
                FlightName: null,
                TeeTimeId: null,
                OpenDateTime: null,
                TeeId: null,
                TournamentId: null,
            }
          } else {
            this.chooseDate = this.data.data.OpenDateTime ? this.data.data.OpenDateTime : this.getUTC('start', 'day')
            this.dataAddFlight.FlightName = this.data.data.FlightName
          }
          await this.getListCourse()
          await this.getListBooking_RTS01()
        }
      }
    }
}
</script>
<style lang="scss">
    #modal-tour-add-flight {
        .selected-background-color{
            background: rgba(17, 74, 159, 0.04);;
        }
        .blocked-background-color{
            background: rgba(193, 193, 193, 0.12);
        }
    }
</style>