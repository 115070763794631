<template>
    <div>
        <b-modal
            id="modal-add-golfer-group"
            size="lg"
            centered
            hide-header
            :no-close-on-backdrop="true"
            v-model="isOpen"
        >
        <b-card-header class="banned-background-color-primary">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 class="mb-0 text-primary">
              {{ $t('golf_tour_add_golfer_to_group') }}
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              color="#114A9F"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
            <treeselect
                v-model="MemberSelect"
                :options="listMember"
                :clearable="false"
                class="d-tree-select"
                placeholder="Chọn Golfer"
                :multiple="true"
                :clear-on-select="false"
            />
        </b-card-body>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="save"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                size="md"
                variant="none"
                class="float-right mr-1"
                @click="hideModal"
                >
                    {{ $t('golf_common_back') }}
                </b-button>
            
            </div>
        </template>
        </b-modal>
    </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import { tour } from '@/api/tour'
export default {
    components: {
        Treeselect
    },
    data() {
        return {
            isOpen: false,
            listMember: [],
            MemberSelect: []
        }
    },
    props: ['tourId', 'data'],
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-add-golfer-group')
        },
        async getListGolfer() {
            const body = {
                TournamentId: this.tourId,
                TournamentGroupId: [],
                TournamentFlightId: [],
                VgaCode: [],
                KeyWord: null,
            }
            await tour.api_TOUR_RGO01(body).then(res => {
                if(res.Status == 200) {
                    res.Data.TournamentMember.forEach(x => {
                        x.id = x.Id
                        x.label = `${x.Vgacode} - ${x.FullName} - ${x.PhoneNumber} - ${x.Email}`
                    })
                    this.listMember = res.Data.TournamentMember.filter(y => !y.TournamentGroupId)
                }
            })
        },
        async save() {
            if(this.MemberSelect.length > 0) {
                const body = {
                    TournamentGroupId: this.data.Id,
                    TournamentMemberId: this.MemberSelect
                }
                await tour.api_TOUR_UGR02(body).then(res => {
                    this.showResToast(res)
                    if(res.Status == 200) {
                        this.$emit('event', { type: 'after-add-golfer' })
                        this.hideModal()
                    }
                })
            } else {

            }
            
        }
    },
    watch: {
        isOpen(value) {
            if(value) {
                this.getListGolfer()
            }
        }
    }
}
</script>
<style lang="scss">
    
</style>