import { courseSetting } from '@/api/course-setting'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'
import { tour } from '@/api/tour.js'
import tabAddGolfer from './tabs/tabAddGolfer.vue'
import divedeTournament from './tabs/divedeTournament.vue'
import divedeFlight from './tabs/divedeFlight.vue'
import ResultTour from './tabs/ResultTour.vue'
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        tabAddGolfer,
        divedeTournament,
        divedeFlight,
        ResultTour
    },
    props: ['data',
        'listFomularScore', 
        'listDefaultParOfHole', 
        'StableFordFormularOfPointDefault', 
        'System36FormularOfPointDefault',
        'DoublePeoriaFormularOfPointDefault',
        'listParOfHoleEnable4Edit',
        'listShowParOfHole',
        'listShowTotalPar',
        'listSelectHole4Formular'
    ],
    data() {
        return {
            isOpen: false,
            dataTab: [
                {
                    value: 'info',
                    label: 'Thông tin giải'
                },
                {
                    value: 'golfer',
                    label: 'Danh sách golfer'
                },
                {
                    value: 'league',
                    label: 'Chia bảng'
                },
                {
                    value: 'group',
                    label: 'Chia nhóm'
                }
            ],
            TabSelect: 0,
            listCourse: [],
            dataAddTour: {
                Code: null,
                Name: null,
                StartDate: this.getUTC('start', 'day'),
                EndDate: this.getUTC('end', 'day'),
                ScoringFormatId: null,
                SponsorName: '',
                Hole: 18,
                Course: [

                ],
                TotalPar: 72
            },
            CourseSelect: null,
            listScoringFormat: [
                {
                    ScoringFormatId: 1,
                    ScoringFormatName: 'Tính điểm'
                }
            ],
            dataTournamentId: null,
            dataDefaultParOfHole: [],
            dataFormualPoint: []
        }
    },
    methods: {
        hideSideBar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-tournament')
        },
        async getListCourse() {
            const response = await courseSetting.getListCourse()
            if (response.Status === '200') {
                this.listCourse = response.Data.Course
            }
        },
        async nextTab() {
            if(this.TabSelect == 0) {
                if(this.data.type == 'DETAIL') {
                    await this.updateTour()
                } else if(this.data.type == 'ADD') {
                    await this.addTournament()
                }
            }
            if(this.TabSelect == 1) {
                // await this.addGolferInTour()
            }
        },
        addTournament() {
            this.$refs.addTour.validate().then(async success => {
                if(success) {
                    if(this.dataAddTour.ScoringFormatId == 'NET_SCORE') {
                        if(!this.dataAddTour.TotalPar) {
                            return this.showToast('error', this.$t('golf_tour_lack_of_formula_conditions'))
                        }
                    } else if (this.dataAddTour.ScoringFormatId == 'SYSTEM_36') {
                        if(this.dataDefaultParOfHole.length == 0 || this.dataFormualPoint.length == 0) {
                            return this.showToast('error', this.$t('golf_tour_lack_of_formula_conditions'))
                        }
                    } else if (this.dataAddTour.ScoringFormatId == 'GROSS_SCORE') {

                    } else if (this.dataAddTour.ScoringFormatId == 'STABLEFORD') {
                        if(this.dataDefaultParOfHole.length == 0 || this.dataFormualPoint.length == 0) {
                            return this.showToast('error', this.$t('golf_tour_lack_of_formula_conditions'))
                        }
                    } else if (this.dataAddTour.ScoringFormatId == 'DOUBLE_PEORIA') {
                        if(this.dataDefaultParOfHole.length == 0 || this.dataFormualPoint.length == 0 || !this.dataAddTour.TotalPar) {
                            return this.showToast('error', this.$t('golf_tour_lack_of_formula_conditions'))
                        }
                    } 
                    const body = {
                        Tournament: {
                            Code: this.dataAddTour.Code,
                            Name: this.dataAddTour.Name,
                            StartDate: this.dataAddTour.StartDate,
                            EndDate: this.dataAddTour.EndDate,
                            ScoringFormatId: String(this.dataAddTour.ScoringFormatId),
                            SponsorName: this.dataAddTour.SponsorName ? this.dataAddTour.SponsorName : '',
                            Hole: this.dataAddTour.Hole,
                            Course: [
                                {
                                    Id: this.CourseSelect.Id,
                                    NumberOfHole: this.dataAddTour.Hole
                                }
                            ],
                            TotalPar: Number(this.dataAddTour.TotalPar)
                        }
                    }
                    if(this.dataAddTour.ScoringFormatId == 'SYSTEM_36' || this.dataAddTour.ScoringFormatId == 'STABLEFORD' || this.dataAddTour.ScoringFormatId == 'DOUBLE_PEORIA') {
                        this.dataDefaultParOfHole.forEach(x => {
                            x.Par = Number(x.Par)
                            if(x.check) {
                                x.Used4ScoreFormular = '1'
                            } else {
                                x.Used4ScoreFormular = null
                            }
                        })
                        this.dataFormualPoint.forEach(x => {
                            x.Score_PAR_From = Number(x.Score_PAR_From),
                            x.Score_PAR_To = Number(x.Score_PAR_To),
                            x.Point = Number(x.Point)
                        })
                        body.Tournament.TournamentHole = this.dataDefaultParOfHole
                        body.Tournament.ScoreFomular = this.dataFormualPoint
                    }
                    await tour.api_TOUR_ITO01(body).then(res => {
                        if(res.Status == '200') {
                            this.dataTournamentId = res.Data.TournamentId
                            this.data.type = 'DETAIL'
                            this.showResToast(res)
                            this.$emit('event', { type: 'after-add-tour', TourId: res.Data.TournamentId })
                        }
                    })
                }
            })
            
        },
        updateTour() {
            this.$refs.addTour.validate().then(async success => {
                if(success) {
                    if(this.dataAddTour.ScoringFormatId == 'NET_SCORE') {
                        if(!this.dataAddTour.TotalPar) {
                            return this.showToast('error', this.$t('golf_tour_lack_of_formula_conditions'))
                        }
                    } else if (this.dataAddTour.ScoringFormatId == 'SYSTEM_36') {
                        if(this.dataDefaultParOfHole.length == 0 || this.dataFormualPoint.length == 0) {
                            return this.showToast('error', this.$t('golf_tour_lack_of_formula_conditions'))
                        }
                    } else if (this.dataAddTour.ScoringFormatId == 'GROSS_SCORE') {

                    } else if (this.dataAddTour.ScoringFormatId == 'STABLEFORD') {
                        if(this.dataDefaultParOfHole.length == 0 || this.dataFormualPoint.length == 0) {
                            return this.showToast('error', this.$t('golf_tour_lack_of_formula_conditions'))
                        }
                    } else if (this.dataAddTour.ScoringFormatId == 'DOUBLE_PEORIA') {
                        if(this.dataDefaultParOfHole.length == 0 || this.dataFormualPoint.length == 0 || !this.dataAddTour.TotalPar) {
                            return this.showToast('error', this.$t('golf_tour_lack_of_formula_conditions'))
                        }
                    } 
                    const body = {
                        Tournament: {
                            Id: this.dataTournamentId,
                            Code: this.dataAddTour.Code,
                            Name: this.dataAddTour.Name,
                            StartDate: this.dataAddTour.StartDate,
                            EndDate: this.dataAddTour.EndDate,
                            ScoringFormatId: String(this.dataAddTour.ScoringFormatId),
                            SponsorName: this.dataAddTour.SponsorName ? this.dataAddTour.SponsorName : '',
                            Hole: this.dataAddTour.Hole,
                            Course: [
                                {
                                    Id: this.CourseSelect.Id,
                                    NumberOfHole: this.dataAddTour.Hole
                                }
                            ],
                            TotalPar: Number(this.dataAddTour.TotalPar)
                        }
                    }
                    if(this.dataAddTour.ScoringFormatId == 'SYSTEM_36' || this.dataAddTour.ScoringFormatId == 'STABLEFORD' || this.dataAddTour.ScoringFormatId == 'DOUBLE_PEORIA') {
                        this.dataDefaultParOfHole.forEach(x => {
                            x.Par = Number(x.Par)
                            if(x.check) {
                                x.Used4ScoreFormular = '1'
                            } else {
                                x.Used4ScoreFormular = null
                            }
                        })
                        this.dataFormualPoint.forEach(x => {
                            x.Score_PAR_From = Number(x.Score_PAR_From),
                            x.Score_PAR_To = Number(x.Score_PAR_To),
                            x.Point = Number(x.Point)
                        })
                        body.Tournament.TournamentHole = this.dataDefaultParOfHole
                        body.Tournament.ScoreFomular = this.dataFormualPoint
                    }
                    await tour.api_TOUR_UTO01(body).then(res => {
                        if(res.Status == '200') {
                            this.showResToast(res)
                            this.$emit('event', { type: 'after-add-tour' })
                        }
                    })
                }
            })
        },
        refreshData() {
            this.dataAddTour = {
                Code: null,
                Name: null,
                StartDate: this.getUTC('start', 'day'),
                EndDate: this.getUTC('end', 'day'),
                ScoringFormatId: null,
                SponsorName: '',
                Hole: 18,
                Course: [

                ],
                TotalPar: 72
            }
        },
        addGolferInTour() {
            this.$refs.tabGolfer.saveGolfer()
        },
        handleEvent(result) {
            if (result.type === 'after-add-golfer') {

              }
        },
        changeCourse() {
            if(this.CourseSelect) {
                this.dataDefaultParOfHole = JSON.parse(JSON.stringify(this.listDefaultParOfHole.filter(x => x.CourseId == this.CourseSelect.Id)))
            }
        },
        changeScoringFormat() {
            if(this.dataAddTour.ScoringFormatId == 'SYSTEM_36') {
                this.dataFormualPoint = this.System36FormularOfPointDefault
            } else if (this.dataAddTour.ScoringFormatId == 'STABLEFORD') {
                this.dataFormualPoint = this.StableFordFormularOfPointDefault
            } else if (this.dataAddTour.ScoringFormatId == 'DOUBLE_PEORIA') {
                this.dataFormualPoint = this.DoublePeoriaFormularOfPointDefault
            } else {
                this.dataFormualPoint = []
            }
            
        },
        addPoint() {
            this.dataFormualPoint.push({
                Score_PAR_From: null,
                Score_PAR_To: null,
                Point: null
            })
        },
        deletePoint(index) {
            this.dataFormualPoint.splice(index,1)
        }
    },
    created() {
        this.getListCourse()
    },
    watch: {
        isOpen(value) {
            if(value) {
                this.TabSelect = 0
                if(this.data.type == 'DETAIL') {
                    this.dataTournamentId = this.data.data.Id
                    this.dataAddTour = {
                        Code: this.data.data.TournamentCode,
                        Name: this.data.data.TournamentName,
                        StartDate: this.data.data.StartDate,
                        EndDate: this.data.data.EndDate,
                        ScoringFormatId: this.data.data.ScoringFormatId,
                        SponsorName: this.data.data.SponsorName,
                        Hole: this.data.data.Hole,
                        Course: [
                        ],
                        TotalPar: Number(this.data.data.TotalPar)
                    }
                    if(this.data.data.Courses[0]) {
                        this.CourseSelect =  this.listCourse.find(x => x.Id == this.data.data.Courses[0].Id)
                    }
                    this.dataDefaultParOfHole = JSON.parse(JSON.stringify(this.data.data.TournamentHole))
                    this.dataDefaultParOfHole.forEach(x => {
                        if(x.Used4ScoreFormular == 1) {
                            x.check = true
                        }
                    })
                    this.dataFormualPoint =  JSON.parse(JSON.stringify(this.data.data.ScoreFomular))
                } else if (this.data.type == 'ADD') {
                    this.dataTournamentId = null
                    this.dataAddTour = {
                        Code: null,
                        Name: null,
                        StartDate: this.getUTC('start', 'day'),
                        EndDate: this.getUTC('end', 'day'),
                        ScoringFormatId: null,
                        SponsorName: null,
                        Hole: 18,
                        Course: [
                        ],
                        TotalPar: 72
                    }
                    this.CourseSelect = null
                }
            } else {
                this.refreshData()
            }
        },
    }
}