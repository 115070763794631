<template>
    <b-card>
        <b-row class="pb-1">
            <b-col cols="2">
                <b-form-group>
                    <v-select  v-model="search.VgaCode" taggable multiple :placeholder="$t('golf_tour_edit_point_vga_code')"
                      class="custom-select-bdc hide-dropdown"
                      @input="getListGolfer(true)"
                      >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No results found for <em>{{ search }}</em>.
                        </template>
                        <em v-else style="opacity: 0.5">{{ $t('golf_tour_edit_point_vga_code') }}</em>
                      </template>
                    </v-select>
                </b-form-group>
                
            </b-col>
            <b-col cols="2">
                <b-form-group>
                    <b-form-input
                        v-model="search.KeyWord"
                        :placeholder="$t('golf_tour_edit_point_key_word')"
                        @change="getListGolfer(true)"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group>
                    <v-select 
                        v-model="search.TournamentGroupId"
                        :options="listGroup"
                        label="GroupName" 
                        :reduce="option => option.Id" 
                        :placeholder="$t('golf_tour_search_group')"
                        :clearable="true"
                        multiple
                        @input="getListGolfer(true)"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group>
                    <v-select 
                        v-model="search.TournamentFlightId"
                        :options="listFight"
                        label="FlightName" 
                        :reduce="option => option.Id" 
                        :placeholder="$t('golf_tour_search_fight')"
                        :clearable="true"
                        multiple
                        @input="getListGolfer(true)"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="1" class="d-flex justify-content-center flex-column">
                <b-form-group>
                    <b-form-checkbox v-model="autoOpenInputScore">
                        {{ $t('golf_tour_auto_open_input_score') }}
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col align="right">
                
               
                <input type="file" class="form-import-excel__field-choosefile d-none" ref="choosefileExcel" @input="readFileExcel"/>
                <b-dropdown
                    variant="outline-primary"
                    class="mr-50"
                    >
                    <template #button-content>
                        <span class="align-middle">{{ $t('golf_common_import_excel') }}</span>
                    </template>
                    <b-dropdown-item @click="triggerClickChooFiles()">
                        {{ $t('golf_common_import_excel') }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="generateXLSX()">
                        {{ $t('golf_common_template_import_to_excel') }}
                    </b-dropdown-item>
                </b-dropdown>
                <b-button
                        v-b-tooltip.hover title="F4"
                      variant="outline-primary"
                      class="mr-50"
                      @click="OpenEditMember"
                    >
                      {{ $t("golf_tour_open_edit_point_member") }}
                </b-button>
                <b-button
                      variant="outline-primary"
                      class="mr-50"
                      @click="ProcessPoint"
                    >
                      {{ $t("golf_tour_point_process") }}
                </b-button>
                <b-button
                      variant="outline-primary"
                      class="mr-50"
                      @click="ExportExcel"
                    >
                      {{ $t("golf_tour_export_excel") }}
                </b-button>
            </b-col>
        </b-row>
        <b-row>
     
            <DxDataGrid
                :ref="dataGridRef"
                :height="heightTable"
                :show-borders="true"
                :data-source="listMemberTour"
                :selected-row-keys="selectedRowKeys"
                :show-column-lines="true"
                :show-row-lines="true"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                column-resizing-mode="widget"
                @selection-changed="onSelectionChanged"
              >
                <DxColumn
                  v-for="(item, i) in configTable"
                  :key="i.field"
                  :caption="item.caption"
                  :data-field="item.field"
                  :data-type="item.type"
                  :cell-template="(item.cellTemplate) ? item.cellTemplate : false"
                  :alignment="item.alignment"
                  :width="item.width"
                  
                >
                  <DxColumn
                    v-for="(band, j) in item.bands"
                    v-if="band.isShow && item.bands.length > 0"
                    :key="band.field"
                    :caption="band.caption"
                    :data-field="band.field"
                    :data-type="band.type"
                    :cell-template="(band.cellTemplate) ? band.cellTemplate : false"
                    :alignment="band.alignment"
                    :width="band.width"
                  />
                </DxColumn>
                <DxSelection
                  :select-all-mode="allMode"
                  :show-check-boxes-mode="checkBoxesMode"
                  mode="multiple"
                />
                <template #full-name="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: left">
                        {{ data.data.FullName }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        Point
                    </p>
                  </div>
                </template>
                <template #vga-code="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Vgacode }}
                    </p>
                  </div>
                </template>
                <template #tour-flight="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.TournamentFlightName }}
                    </p>
                  </div>
                </template>
                <template #hole-1="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole1 ? data.data.Hole1 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point1  ? data.data.Point1 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-2="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole2 ? data.data.Hole2 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point2  ? data.data.Point2 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-3="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole3 ? data.data.Hole3 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point3  ? data.data.Point3 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-4="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole4 ? data.data.Hole4 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point4  ? data.data.Point4 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-5="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole5 ? data.data.Hole5 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point5  ? data.data.Point5 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-6="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole6 ? data.data.Hole6 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point6  ? data.data.Point6 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-7="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole7 ? data.data.Hole7 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point7  ? data.data.Point7 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-8="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole8 ? data.data.Hole8 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point8  ? data.data.Point8 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-9="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole9 ? data.data.Hole9 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point9  ? data.data.Point9 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-10="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole10 ? data.data.Hole10 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point10  ? data.data.Point10 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-11="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole11 ? data.data.Hole11 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point11  ? data.data.Point11 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-12="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole12 ? data.data.Hole12 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point12  ? data.data.Point12 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-13="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole13 ? data.data.Hole13 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point13  ? data.data.Point13 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-14="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole14 ? data.data.Hole14 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point14  ? data.data.Point14 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-15="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole15 ? data.data.Hole15 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point15  ? data.data.Point15 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-16="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole16 ? data.data.Hole16 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point16  ? data.data.Point16 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-17="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole17 ? data.data.Hole17 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point17  ? data.data.Point17 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-18="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole18 ? data.data.Hole18 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point18  ? data.data.Point18 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-19="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole19 ? data.data.Hole19 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point19  ? data.data.Point19 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-20="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole20 ? data.data.Hole20 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point20  ? data.data.Point20 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-21="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole21 ? data.data.Hole21 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point21  ? data.data.Point21 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-22="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole22 ? data.data.Hole22 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point22  ? data.data.Point22 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-23="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole23 ? data.data.Hole23 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point23  ? data.data.Point23 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-24="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole24 ? data.data.Hole24 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point24  ? data.data.Point24 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-25="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole25 ? data.data.Hole25 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point25  ? data.data.Point25 : 0 }}
                    </p>
                  </div>
                </template>

                <template #hole-26="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole26 ? data.data.Hole26 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point26  ? data.data.Point26 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-27="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole27 ? data.data.Hole27 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point27  ? data.data.Point27 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-28="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole28 ? data.data.Hole28 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point28  ? data.data.Point28 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-29="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole29 ? data.data.Hole29 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point29  ? data.data.Point29 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-30="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole30 ? data.data.Hole30 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point30  ? data.data.Point30 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-31="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole31 ? data.data.Hole31 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point31  ? data.data.Point31 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-32="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole32 ? data.data.Hole32 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point32  ? data.data.Point32 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-33="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole33 ? data.data.Hole33 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point33  ? data.data.Point33 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-34="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole34 ? data.data.Hole34 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point34  ? data.data.Point34 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-35="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole35 ? data.data.Hole35 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point35  ? data.data.Point35 : 0 }}
                    </p>
                  </div>
                </template>
                <template #hole-36="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.Hole36 ? data.data.Hole36 : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.Point36  ? data.data.Point36 : 0 }}
                    </p>
                  </div>
                </template>
                <template #Gross="{ data }">
                  <div class="text-truncate">
                    <p style="text-align: center;" >
                        {{ data.data.GrossByFormular ? data.data.GrossByFormular : 0 }}
                    </p>
                    <p style="border-top: 1px solid #E9EBF1;text-align: center;padding-top: 5px">
                        {{ data.data.PointByFormular  ? data.data.PointByFormular : 0 }}
                    </p>
                  </div>
                </template>
          </DxDataGrid>
        </b-row>
        <modalEditPointMember :data="dataPropsEditMember" :dataTour="data"  @event="handleEvent" @is-open="SidebarOpenOrCloseHandler" />
        <ShortKey
            :shortcuts="ShortCutKey"
            :is-active="true"
            :all-child-component-closed="allChildComponentClosed"
            @triggered="onTriggeredEventHandler"
            />
    </b-card>
</template>
<script>
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S, P
} from '@/api/constant.js'
import ExcelJS from "exceljs";
import readXlsxFile from 'read-excel-file';
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import { tour } from '@/api/tour'
import modalEditPointMember from './modal/modalEditPointMember.vue'
import ShortKey from '@/views/modules/ultils/ShortKey'

import {
  DxToolbar,
  DxItem,
  DxTexts,
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
  DxLoadPanel,
  DxKeyboardNavigation,
} from 'devextreme-vue/data-grid'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import 'devextreme/dist/css/dx.light.css'
const dataGridRef = 'dataGrid'
export default {
    props: ['data', 'tourId'],
    components: {
        modalEditPointMember,
        DxToolbar,
        DxItem,
        DxTexts,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRequiredRule,
        DxRangeRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport,
        DxLoadPanel,
        DxKeyboardNavigation,
        ShortKey
    },
    data() {
        return {
            dataGridRef,
            listMemberTour: null,
            fields: [],
            heightTable: 400,
            windowHeight: 0,
            dataPropsEditMember: [],
            search: {
                VgaCode: [],
                KeyWord: null,
                TournamentFlightId: [],
                TournamentGroupId: []
            },
            checkAll: false,
            allMode: 'allPages',
            checkBoxesMode: 'onClick', // 'always' :
            selectedRowsData: [],
            listGroup: [],
            listFight: [],
            ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S, P],
            allChildComponentClosed: [
                {
                    ComponentId: 'modal-edit-point-member',
                    IsActive: false,
                }
            ],
            autoOpenInputScore: false
        }
    },
    created() {
        this.getListGolfer()
        this.getListGroup()
        this.getListFight()
    },
    computed: {
        selectedRowKeys() {
            return this.selectedRowsData
        },
      dataGrid() {
        return this.$refs[dataGridRef].instance
      },
      configTable() {
        const header = [
        {
            caption: 'STT',
            field: 'Index',
            isShow: true,
            alignment: 'center',
            width: '3%'
          },
          {
            caption: 'Họ tên',
            field: 'FullName',
            isShow: true,
            cellTemplate: 'full-name',
            alignment: 'center',
            width: '10%'
          },
          {
            caption: 'Mã VGA',
            field: 'Vgacode',
            isShow: true,
            cellTemplate: 'vga-code',
            alignment: 'center',
            width: '5%'
          },
          {
            caption: 'Mã nhóm',
            field: 'TournamentFlightName',
            isShow: true,
            cellTemplate: 'tour-flight',
            alignment: 'center',
            width: '5%'
          }
        ]
        for(let i = 0; i < this.data.Hole; i++) {
            header.push({
                caption: `H${i + 1}`,
                field: `Hole${i+1}`,
                isShow: true,
                cellTemplate: `hole-${i+1}`,
                alignment: 'center',
                width: `${55/this.data.Hole}%`
            })
        }
        header.push({
                caption: `Gross`,
                field: `GrossByFormular`,
                isShow: true,
                cellTemplate: `Gross`,
                alignment: 'center',
                width: '4%'
            })
        header.push({
            caption: `Hdc`,
            field: `HdcparByFormular`,
            isShow: true,
            // cellTemplate: `Point`,
            alignment: 'center',
            width: '4%'
        })
        header.push({
            caption: `Net`,
            field: `NetByFormular`,
            isShow: true,
            // cellTemplate: `Net`,
            alignment: 'center',
            width: '4%'
        })
        header.push({
            caption: `Rank`,
            field: `Rank`,
            isShow: true,
            // cellTemplate: `Net`,
            alignment: 'center',
            width: '5%'
        })
        header.push({
            caption: `Rank Group`,
            field: `RankGroup`,
            isShow: true,
            // cellTemplate: `Net`,
            alignment: 'center',
            width: '5%'
        })
        return header
      }
    },
    watch: {
        windowHeight(value) {
            this.heightTable = value - 290
        }
    },
    mounted() {
        this.windowHeight = window.innerHeight
    },
    methods: {
        triggerClickChooFiles() {
            if(this.data.Courses.length > 0) {
                const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
                fromChooseFile.click();
            } else {
                this.showToast('error', this.$t('golf_tour_please_select_course_to_tour'))
            }
            
        },
        getSelectedData() {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData()
        },
        generateXLSX() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Format Result Tour");
            const column = []
            if(this.data.Hole == 9) {
                worksheet.columns = [
                    { header: "Họ tên", key: "FullName"},
                    { header: "Mã Vga", key: "Vgacode"},
                    { header: "Hole 1", key: "Hole1"},
                    { header: "Hole 2", key: "Hole2"},
                    { header: "Hole 3", key: "Hole3"},
                    { header: "Hole 4", key: "Hole4"},
                    { header: "Hole 5", key: "Hole5"},
                    { header: "Hole 6", key: "Hole6"},
                    { header: "Hole 7", key: "Hole7"},
                    { header: "Hole 8", key: "Hole8"},
                    { header: "Hole 9", key: "Hole9"},
                ];
            } else if (this.data.Hole == 18) {
                worksheet.columns = [
                    { header: "Họ tên", key: "FullName"},
                    { header: "Mã Vga", key: "Vgacode"},
                    { header: "Hole 1", key: "Hole1"},
                    { header: "Hole 2", key: "Hole2"},
                    { header: "Hole 3", key: "Hole3"},
                    { header: "Hole 4", key: "Hole4"},
                    { header: "Hole 5", key: "Hole5"},
                    { header: "Hole 6", key: "Hole6"},
                    { header: "Hole 7", key: "Hole7"},
                    { header: "Hole 8", key: "Hole8"},
                    { header: "Hole 9", key: "Hole9"},
                    { header: "Hole 10", key: "Hole10"},
                    { header: "Hole 11", key: "Hole11"},
                    { header: "Hole 12", key: "Hole12"},
                    { header: "Hole 13", key: "Hole13"},
                    { header: "Hole 14", key: "Hole14"},
                    { header: "Hole 15", key: "Hole15"},
                    { header: "Hole 16", key: "Hole16"},
                    { header: "Hole 17", key: "Hole17"},
                    { header: "Hole 18", key: "Hole18"},
                ];
            } else if (this.data.Hole == 27) {
                worksheet.columns = [
                    { header: "Họ tên", key: "FullName"},
                    { header: "Mã Vga", key: "Vgacode"},
                    { header: "Hole 1", key: "Hole1"},
                    { header: "Hole 2", key: "Hole2"},
                    { header: "Hole 3", key: "Hole3"},
                    { header: "Hole 4", key: "Hole4"},
                    { header: "Hole 5", key: "Hole5"},
                    { header: "Hole 6", key: "Hole6"},
                    { header: "Hole 7", key: "Hole7"},
                    { header: "Hole 8", key: "Hole8"},
                    { header: "Hole 9", key: "Hole9"},
                    { header: "Hole 10", key: "Hole10"},
                    { header: "Hole 11", key: "Hole11"},
                    { header: "Hole 12", key: "Hole12"},
                    { header: "Hole 13", key: "Hole13"},
                    { header: "Hole 14", key: "Hole14"},
                    { header: "Hole 15", key: "Hole15"},
                    { header: "Hole 16", key: "Hole16"},
                    { header: "Hole 17", key: "Hole17"},
                    { header: "Hole 18", key: "Hole18"},
                    { header: "Hole 19", key: "Hole19"},
                    { header: "Hole 20", key: "Hole20"},
                    { header: "Hole 21", key: "Hole21"},
                    { header: "Hole 22", key: "Hole22"},
                    { header: "Hole 23", key: "Hole23"},
                    { header: "Hole 24", key: "Hole24"},
                    { header: "Hole 25", key: "Hole25"},
                    { header: "Hole 26", key: "Hole26"},
                    { header: "Hole 27", key: "Hole27"},
                ];
            } else {
                worksheet.columns = [
                    { header: "Họ tên", key: "FullName"},
                    { header: "Mã Vga", key: "Vgacode"},
                    { header: "Hole 1", key: "Hole1"},
                    { header: "Hole 2", key: "Hole2"},
                    { header: "Hole 3", key: "Hole3"},
                    { header: "Hole 4", key: "Hole4"},
                    { header: "Hole 5", key: "Hole5"},
                    { header: "Hole 6", key: "Hole6"},
                    { header: "Hole 7", key: "Hole7"},
                    { header: "Hole 8", key: "Hole8"},
                    { header: "Hole 9", key: "Hole9"},
                    { header: "Hole 10", key: "Hole10"},
                    { header: "Hole 11", key: "Hole11"},
                    { header: "Hole 12", key: "Hole12"},
                    { header: "Hole 13", key: "Hole13"},
                    { header: "Hole 14", key: "Hole14"},
                    { header: "Hole 15", key: "Hole15"},
                    { header: "Hole 16", key: "Hole16"},
                    { header: "Hole 17", key: "Hole17"},
                    { header: "Hole 18", key: "Hole18"},
                    { header: "Hole 19", key: "Hole19"},
                    { header: "Hole 20", key: "Hole20"},
                    { header: "Hole 21", key: "Hole21"},
                    { header: "Hole 22", key: "Hole22"},
                    { header: "Hole 23", key: "Hole23"},
                    { header: "Hole 24", key: "Hole24"},
                    { header: "Hole 25", key: "Hole25"},
                    { header: "Hole 26", key: "Hole26"},
                    { header: "Hole 27", key: "Hole27"},
                    { header: "Hole 28", key: "Hole28"},
                    { header: "Hole 29", key: "Hole29"},
                    { header: "Hole 30", key: "Hole30"},
                    { header: "Hole 31", key: "Hole31"},
                    { header: "Hole 32", key: "Hole32"},
                    { header: "Hole 33", key: "Hole33"},
                    { header: "Hole 34", key: "Hole34"},
                    { header: "Hole 35", key: "Hole35"},
                    { header: "Hole 36", key: "Hole36"},
                ];
            }
            
            worksheet.columns = column
            workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `TemplateResultTournamentExport.xlsx`)
            })
        },
        async readFileExcel() {
            await readXlsxFile(this.$refs.choosefileExcel.files[0]).then(async (rows) => {
                var data = []
                rows.forEach((x, indexRow) => {
                    if(indexRow != 0 ) {
                        if(this.listMemberTour.find(y => y.Vgacode == x[1])) {
                            x.forEach((item, index) => {
                                if(index > 1 && item) {
                                    data.push(
                                        {
                                            TournamentMemberId: this.listMemberTour.find(y => y.Vgacode == x[1]).Id,
                                            CourseId: this.data.Courses[0].Id,
                                            Hole: index - 1,
                                            Stoke: Number(item)
                                        }
                                    )
                                }
                            })
                        } else {
                            this.showToast('error', this.$t('golf_tour_not_found_vga_code'))
                        }
                    }
                })
                if(data.length > 0) {
                    const body = {
                        TournamentScore: data
                    }
                    await tour.api_TOUR_UPA02(body).then(res => {
                        this.showResToast(res)
                        if(res.Status == 200) {
                            this.getListGolfer()
                        }
                    })
                }
            });
            const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
            fromChooseFile.value = ""
        },
        async getListGolfer(check) {
            const body = {
                TournamentId: this.tourId,
                TournamentGroupId: this.search.TournamentGroupId,
                TournamentFlightId: this.search.TournamentFlightId,
                VgaCode: this.search.VgaCode,
                KeyWord: this.search.KeyWord,
                isGetStoke: true,
                isGetRanking: true
            }
            await tour.api_TOUR_RGO01(body).then(res => {
            if(res.Status == 200) {
                if(res.Data?.TournamentMember.length > 0) {
                    this.fields = res.Data?.TournamentMember[0].TournamentMemberScore.map(Score => ('H' + Score.Hole))
                }
                const data = []
                res.Data.TournamentMember.forEach((x, index) => {
                    x.Index = index + 1
                    x.check = false
                    x.TournamentMemberScore.forEach(y => {
                        if(y.Hole == 1) {
                            x.Hole1 = y.Stoke
                            x.Point1 = y.PointByFomular
                        } else if (y.Hole == 2) {
                            x.Hole2 = y.Stoke
                            x.Point2 = y.PointByFomular
                        } else if (y.Hole == 3) {
                            x.Hole3 = y.Stoke
                            x.Point3 = y.PointByFomular
                        } else if (y.Hole == 4) {
                            x.Hole4 = y.Stoke
                            x.Point4 = y.PointByFomular
                        } else if (y.Hole == 5) {
                            x.Hole5 = y.Stoke
                            x.Point5 = y.PointByFomular
                        } else if (y.Hole == 6) {
                            x.Hole6 = y.Stoke
                            x.Point6 = y.PointByFomular
                        } else if (y.Hole == 7) {
                            x.Hole7 = y.Stoke
                            x.Point7 = y.PointByFomular
                        } else if (y.Hole == 8) {
                            x.Hole8 = y.Stoke
                            x.Point8 = y.PointByFomular
                        } else if (y.Hole == 9) {
                            x.Hole9 = y.Stoke
                            x.Point9 = y.PointByFomular
                        } else if (y.Hole == 10) {
                            x.Hole10 = y.Stoke
                            x.Point10 = y.PointByFomular
                        } else if (y.Hole == 11) {
                            x.Hole11 = y.Stoke
                            x.Point11 = y.PointByFomular
                        } else if (y.Hole == 12) {
                            x.Hole12 = y.Stoke
                            x.Point12 = y.PointByFomular
                        } else if (y.Hole == 13) {
                            x.Hole13 = y.Stoke
                            x.Point13 = y.PointByFomular
                        } else if (y.Hole == 14) {
                            x.Hole14 = y.Stoke
                            x.Point14 = y.PointByFomular
                        } else if (y.Hole == 15) {
                            x.Hole15 = y.Stoke
                            x.Point15 = y.PointByFomular
                        } else if (y.Hole == 16) {
                            x.Hole16 = y.Stoke
                            x.Point16 = y.PointByFomular
                        } else if (y.Hole == 17) {
                            x.Hole17 = y.Stoke
                            x.Point17 = y.PointByFomular
                        } else if (y.Hole == 18) {
                            x.Hole18 = y.Stoke
                            x.Point18 = y.PointByFomular
                        } else if (y.Hole == 19) {
                            x.Hole19 = y.Stoke
                            x.Point19 = y.PointByFomular
                        } else if (y.Hole == 20) {
                            x.Hole20 = y.Stoke
                            x.Point20 = y.PointByFomular
                        } else if (y.Hole == 21) {
                            x.Hole21 = y.Stoke
                            x.Point21 = y.PointByFomular
                        } else if (y.Hole == 22) {
                            x.Hole22 = y.Stoke
                            x.Point22 = y.PointByFomular
                        } else if (y.Hole == 23) {
                            x.Hole23 = y.Stoke
                            x.Point23 = y.PointByFomular
                        } else if (y.Hole == 24) {
                            x.Hole24 = y.Stoke
                            x.Point24 = y.PointByFomular
                        } else if (y.Hole == 25) {
                            x.Hole25 = y.Stoke
                            x.Point25 = y.PointByFomular
                        } else if (y.Hole == 26) {
                            x.Hole26 = y.Stoke
                            x.Point26 = y.PointByFomular
                        } else if (y.Hole == 27) {
                            x.Hole27 = y.Stoke
                            x.Point27 = y.PointByFomular
                        } else if (y.Hole == 28) {
                            x.Hole28 = y.Stoke
                            x.Point28 = y.PointByFomular
                        } else if (y.Hole == 29) {
                            x.Hole29 = y.Stoke
                            x.Point29 = y.PointByFomular
                        } else if (y.Hole == 30) {
                            x.Hole30 = y.Stoke
                            x.Point30 = y.PointByFomular
                        } else if (y.Hole == 31) {
                            x.Hole31 = y.Stoke
                            x.Point31 = y.PointByFomular
                        } else if (y.Hole == 32) {
                            x.Hole32 = y.Stoke
                            x.Point32 = y.PointByFomular
                        } else if (y.Hole == 33) {
                            x.Hole33 = y.Stoke
                            x.Point33 = y.PointByFomular
                        } else if (y.Hole == 34) {
                            x.Hole34 = y.Stoke
                            x.Point34 = y.PointByFomular
                        } else if (y.Hole == 35) {
                            x.Hole35 = y.Stoke
                            x.Point35 = y.PointByFomular
                        } else if (y.Hole == 36) {
                            x.Hole36 = y.Stoke
                            x.Point36 = y.PointByFomular
                        }
                    })
                    data.push(JSON.parse(JSON.stringify(x)))
                    data.push({
                        Id: x.Id,
                        FullName: 'Point',
                        IsPoint: true,
                        Hole1: x.Point1,
                        Hole2: x.Point2,
                        Hole3: x.Point3,
                        Hole4: x.Point4,
                        Hole5: x.Point5,
                        Hole6: x.Point6,
                        Hole7: x.Point7,
                        Hole8: x.Point8,
                        Hole9: x.Point9,
                        Hole10: x.Point10,
                        Hole11: x.Point11,
                        Hole12: x.Point12,
                        Hole13: x.Point13,
                        Hole14: x.Point14,
                        Hole15: x.Point15,
                        Hole16: x.Point16,
                        Hole17: x.Point17,
                        Hole18: x.Point18,
                        Hole19: x.Point19,
                        Hole20: x.Point20,
                        Hole21: x.Point21,
                        Hole22: x.Point22,
                        Hole23: x.Point23,
                        Hole24: x.Point24,
                        Hole25: x.Point25,
                        Hole26: x.Point26,
                        Hole27: x.Point27,
                        Hole28: x.Point28,
                        Hole29: x.Point29,
                        Hole30: x.Point30,
                        Hole31: x.Point31,
                        Hole32: x.Point32,
                        Hole33: x.Point33,
                        Hole34: x.Point34,
                        Hole35: x.Point35,
                        Hole36: x.Point36
                    })
                })
                this.listMemberTour = res.Data.TournamentMember
                if(check && this.autoOpenInputScore) {
                    if(this.search.TournamentGroupId.length > 0 || this.search.VgaCode.length > 0 || this.search.TournamentFlightId.length > 0 || this.search.KeyWord) {
                        this.selectedRowsData = this.listMemberTour
                        setTimeout(() => {
                            this.OpenEditMember()
                        },500)
                    }
                }
            }
            })
        },
        OpenEditMember() {
            this.getSelectedData()
            // console.log(this.selectedRowsData)
            // const dataTemp = []
            // this.listMemberTour.forEach(x => {
            //     if(x.check) {
            //         dataTemp.push(x)
            //     }
            // })
            if(this.selectedRowsData.length > 0) {
                this.dataPropsEditMember = JSON.parse(JSON.stringify(this.selectedRowsData))
                this.dataPropsEditMember.forEach((x,index) => {
                    x.Total9 = 0
                    x.Total18 = 0
                    x.Total27 = 0
                    x.Total36 = 0
                    x.TournamentMemberScore.forEach(y => {
                        if (y.Hole <= 9) {
                            x.Total9 += y.Stoke
                        }
                        if (y.Hole <= 18) {
                            x.Total18 += y.Stoke
                        }
                        if (y.Hole <= 27) {
                            x.Total27 += y.Stoke
                        }
                        if (y.Hole <= 36) {
                            x.Total36 += y.Stoke
                        }
                        y.TabIndex = this.data.Hole*index + y.Hole
                    })
                })
                this.$bvModal.show('modal-edit-point-member')
            } else {
                this.showToast('error', this.$t('golf_tour_please_select_one_person'))
            }
            
        },
        async getListGroup() {
            const body = {
                TournamentId: this.tourId,
                TournamentGroupId: null,
                GroupName: null
            }
            await tour.api_TOUR_RGR01(body).then(res => {
                if(res.Status == 200) {
                    this.listGroup = res.Data.TournamentGroup
                }
            })
        },
        async getListFight() {
            const body = {
                TournamentId: this.tourId,
            }
            await tour.api_TOUR_RTE01(body).then(res => {
                if(res.Status == 200) {
                    this.listFight = res.Data.Flight
                }
            })
        },
        handleEvent(result) {
            if (result.type === 'after-edit-point') {
                this.getListGolfer()
            }
        },
        changeCheckAll(data) {
            this.listMemberTour.forEach(x => {
                x.check = data
            })
        },
        async ProcessPoint() {
            const body = {
                TournamentId: this.tourId,
                TournamentMemberId:[],
            }
            await tour.api_TOUR_UPA03(body).then(res => {
                this.showResToast(res)
                if(res.Status == 200) {
                    this.getListGolfer()
                }
            })
        },
        ExportExcel() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Format Result Tour");
            const column = []
            if(this.data.Hole == 9) {
                worksheet.columns = [
                    { header: "STT", key: "Index"},
                    { header: "Họ tên", key: "FullName"},
                    { header: "Mã Vga", key: "Vgacode"},
                    { header: "Bảng", key: "TournamentGroupName"},
                    { header: "Nhóm", key: "TournamentFlightName"},
                    { header: "Hole 1", key: "Hole1"},
                    { header: "Hole 2", key: "Hole2"},
                    { header: "Hole 3", key: "Hole3"},
                    { header: "Hole 4", key: "Hole4"},
                    { header: "Hole 5", key: "Hole5"},
                    { header: "Hole 6", key: "Hole6"},
                    { header: "Hole 7", key: "Hole7"},
                    { header: "Hole 8", key: "Hole8"},
                    { header: "Hole 9", key: "Hole9"},
                    { header: "Gross", key: "GrossByFormular"},
                    { header: "Point", key: "PointByFormular"},
                    { header: "Net", key: "NetByFormular"},
                    { header: "Rank", key: "Rank"},
                    { header: "Rank Group", key: "RankGroup"},
                ];
            } else if (this.data.Hole == 18) {
                worksheet.columns = [
                    { header: "STT", key: "Index"},
                    { header: "Họ tên", key: "FullName"},
                    { header: "Mã Vga", key: "Vgacode"},
                    { header: "Bảng", key: "TournamentGroupName"},
                    { header: "Nhóm", key: "TournamentFlightName"},
                    { header: "Hole 1", key: "Hole1"},
                    { header: "Hole 2", key: "Hole2"},
                    { header: "Hole 3", key: "Hole3"},
                    { header: "Hole 4", key: "Hole4"},
                    { header: "Hole 5", key: "Hole5"},
                    { header: "Hole 6", key: "Hole6"},
                    { header: "Hole 7", key: "Hole7"},
                    { header: "Hole 8", key: "Hole8"},
                    { header: "Hole 9", key: "Hole9"},
                    { header: "Hole 10", key: "Hole10"},
                    { header: "Hole 11", key: "Hole11"},
                    { header: "Hole 12", key: "Hole12"},
                    { header: "Hole 13", key: "Hole13"},
                    { header: "Hole 14", key: "Hole14"},
                    { header: "Hole 15", key: "Hole15"},
                    { header: "Hole 16", key: "Hole16"},
                    { header: "Hole 17", key: "Hole17"},
                    { header: "Hole 18", key: "Hole18"},
                    { header: "Gross", key: "GrossByFormular"},
                    { header: "Point", key: "PointByFormular"},
                    { header: "Net", key: "NetByFormular"},
                    { header: "Rank", key: "Rank"},
                    { header: "Rank Group", key: "RankGroup"},
                ];
            } else if (this.data.Hole == 27) {
                worksheet.columns = [
                    { header: "STT", key: "Index"},
                    { header: "Họ tên", key: "FullName"},
                    { header: "Mã Vga", key: "Vgacode"},
                    { header: "Bảng", key: "TournamentGroupName"},
                    { header: "Nhóm", key: "TournamentFlightName"},
                    { header: "Hole 1", key: "Hole1"},
                    { header: "Hole 2", key: "Hole2"},
                    { header: "Hole 3", key: "Hole3"},
                    { header: "Hole 4", key: "Hole4"},
                    { header: "Hole 5", key: "Hole5"},
                    { header: "Hole 6", key: "Hole6"},
                    { header: "Hole 7", key: "Hole7"},
                    { header: "Hole 8", key: "Hole8"},
                    { header: "Hole 9", key: "Hole9"},
                    { header: "Hole 10", key: "Hole10"},
                    { header: "Hole 11", key: "Hole11"},
                    { header: "Hole 12", key: "Hole12"},
                    { header: "Hole 13", key: "Hole13"},
                    { header: "Hole 14", key: "Hole14"},
                    { header: "Hole 15", key: "Hole15"},
                    { header: "Hole 16", key: "Hole16"},
                    { header: "Hole 17", key: "Hole17"},
                    { header: "Hole 18", key: "Hole18"},
                    { header: "Hole 19", key: "Hole19"},
                    { header: "Hole 20", key: "Hole20"},
                    { header: "Hole 21", key: "Hole21"},
                    { header: "Hole 22", key: "Hole22"},
                    { header: "Hole 23", key: "Hole23"},
                    { header: "Hole 24", key: "Hole24"},
                    { header: "Hole 25", key: "Hole25"},
                    { header: "Hole 26", key: "Hole26"},
                    { header: "Hole 27", key: "Hole27"},
                    { header: "Gross", key: "GrossByFormular"},
                    { header: "Point", key: "PointByFormular"},
                    { header: "Net", key: "NetByFormular"},
                    { header: "Rank", key: "Rank"},
                    { header: "Rank Group", key: "RankGroup"},
                ];
            } else {
                worksheet.columns = [
                    { header: "STT", key: "Index"},
                    { header: "Họ tên", key: "FullName"},
                    { header: "Mã Vga", key: "Vgacode"},
                    { header: "Bảng", key: "TournamentGroupName"},
                    { header: "Nhóm", key: "TournamentFlightName"},
                    { header: "Hole 1", key: "Hole1"},
                    { header: "Hole 2", key: "Hole2"},
                    { header: "Hole 3", key: "Hole3"},
                    { header: "Hole 4", key: "Hole4"},
                    { header: "Hole 5", key: "Hole5"},
                    { header: "Hole 6", key: "Hole6"},
                    { header: "Hole 7", key: "Hole7"},
                    { header: "Hole 8", key: "Hole8"},
                    { header: "Hole 9", key: "Hole9"},
                    { header: "Hole 10", key: "Hole10"},
                    { header: "Hole 11", key: "Hole11"},
                    { header: "Hole 12", key: "Hole12"},
                    { header: "Hole 13", key: "Hole13"},
                    { header: "Hole 14", key: "Hole14"},
                    { header: "Hole 15", key: "Hole15"},
                    { header: "Hole 16", key: "Hole16"},
                    { header: "Hole 17", key: "Hole17"},
                    { header: "Hole 18", key: "Hole18"},
                    { header: "Hole 19", key: "Hole19"},
                    { header: "Hole 20", key: "Hole20"},
                    { header: "Hole 21", key: "Hole21"},
                    { header: "Hole 22", key: "Hole22"},
                    { header: "Hole 23", key: "Hole23"},
                    { header: "Hole 24", key: "Hole24"},
                    { header: "Hole 25", key: "Hole25"},
                    { header: "Hole 26", key: "Hole26"},
                    { header: "Hole 27", key: "Hole27"},
                    { header: "Hole 28", key: "Hole28"},
                    { header: "Hole 29", key: "Hole29"},
                    { header: "Hole 30", key: "Hole30"},
                    { header: "Hole 31", key: "Hole31"},
                    { header: "Hole 32", key: "Hole32"},
                    { header: "Hole 33", key: "Hole33"},
                    { header: "Hole 34", key: "Hole34"},
                    { header: "Hole 35", key: "Hole35"},
                    { header: "Hole 36", key: "Hole36"},
                    { header: "Gross", key: "GrossByFormular"},
                    { header: "Point", key: "PointByFormular"},
                    { header: "Net", key: "NetByFormular"},
                    { header: "Rank", key: "Rank"},
                    { header: "Rank Group", key: "RankGroup"},
                ];
            }
            worksheet.columns = column
            this.listMemberTour.forEach(x => {
                worksheet.addRow({
                    Index: x.Index,
                    FullName: x.FullName,
                    Vgacode: x.Vgacode,
                    TournamentGroupName: x.TournamentGroupName,
                    TournamentFlightName: x.TournamentFlightName,
                    Hole1: x.Hole1,
                    Hole2: x.Hole2,
                    Hole3: x.Hole3,
                    Hole4: x.Hole4,
                    Hole5: x.Hole5,
                    Hole6: x.Hole6,
                    Hole7: x.Hole7,
                    Hole8: x.Hole8,
                    Hole9: x.Hole9,
                    Hole10: x.Hole10,
                    Hole11: x.Hole11,
                    Hole12: x.Hole12,
                    Hole13: x.Hole13,
                    Hole14: x.Hole14,
                    Hole15: x.Hole15,
                    Hole16: x.Hole16,
                    Hole17: x.Hole17,
                    Hole18: x.Hole18,
                    Hole19: x.Hole19,
                    Hole20: x.Hole20,
                    Hole21: x.Hole21,
                    Hole22: x.Hole22,
                    Hole23: x.Hole23,
                    Hole24: x.Hole24,
                    Hole25: x.Hole25,
                    Hole26: x.Hole26,
                    Hole27: x.Hole27,
                    Hole28: x.Hole28,
                    Hole29: x.Hole29,
                    Hole30: x.Hole30,
                    Hole31: x.Hole31,
                    Hole32: x.Hole32,
                    Hole33: x.Hole33,
                    Hole34: x.Hole34,
                    Hole35: x.Hole35,
                    Hole36: x.Hole36,
                    GrossByFormular: x.GrossByFormular,
                    PointByFormular: x.PointByFormular,
                    NetByFormular: x.NetByFormular,
                    Rank: x.Rank,
                    RankGroup: x.RankGroup
                })
                worksheet.addRow({
                    Index: '',
                    FullName: 'Point',
                    Vgacode: '',
                    Hole1: x.Point1,
                    Hole2: x.Point2,
                    Hole3: x.Point3,
                    Hole4: x.Point4,
                    Hole5: x.Point5,
                    Hole6: x.Point6,
                    Hole7: x.Point7,
                    Hole8: x.Point8,
                    Hole9: x.Point9,
                    Hole10: x.Point10,
                    Hole11: x.Point11,
                    Hole12: x.Point12,
                    Hole13: x.Point13,
                    Hole14: x.Point14,
                    Hole15: x.Point15,
                    Hole16: x.Point16,
                    Hole17: x.Point17,
                    Hole18: x.Point18,
                    Hole19: x.Point19,
                    Hole20: x.Point20,
                    Hole21: x.Point21,
                    Hole22: x.Point22,
                    Hole23: x.Point23,
                    Hole24: x.Point24,
                    Hole25: x.Point25,
                    Hole26: x.Point26,
                    Hole27: x.Point27,
                    Hole28: x.Point28,
                    Hole29: x.Point29,
                    Hole30: x.Point30,
                    Hole31: x.Point31,
                    Hole32: x.Point32,
                    Hole33: x.Point33,
                    Hole34: x.Point34,
                    Hole35: x.Point35,
                    Hole36: x.Point36,
                })
            })
            workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `ResultTournament_${this.data.TournamentName}_${this.convertUTC(this.data.StartDate, 'DD/MM/YYYY' )}.xlsx`)
            })
        },
        onSelectionChanged(e) {
            this.selectedRowsData = e.selectedRowsData
        },
        onTriggeredEventHandler(payload) {
            if (payload.key === F4) {
                this.OpenEditMember()
            }  
            if (payload.evt.ctrlKey && payload.key === A) {
                if (this.selectedRowsData.length != this.listMemberTour.length) {
                    this.selectedRowsData = this.listMemberTour
                } else {
                    this.dataGrid.clearSelection()
                }
            }
        },
        SidebarOpenOrCloseHandler(val) {
            this.allChildComponentClosed.find(x => {
                if (x.ComponentId === val.ComponentId) {
                    x.IsActive = val.IsActive
                }
            })
            },
    },
}
</script>
<style lang="scss">
    .dx-checkbox-container {
        justify-content: center;
    }
</style>