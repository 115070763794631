<template>
    <div>
        <b-modal
            id="modal-add-member"
            size="sm"
            centered
            hide-header
            :no-close-on-backdrop="true"
            v-model="isOpen"
        >
        <b-card-header class="banned-background-color-primary">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 v-if="data.type == 'ADD'" class="mb-0 text-primary">
              {{ $t('golf_tour_add_member') }}
            </h4>
            <h4 v-else class="mb-0 text-primary">
              {{ $t('golf_tour_update_member') }}
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              color="#114A9F"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
            <validation-observer
                ref="addGroup"
                tag="div"
            >
                <b-form
                    class="detail-caddy-group"
                    @submit.stop.prevent="handleSubmit"
                >
                    <b-row>
                        <b-col cols="12">
                            <b-form-group :label="$t('golf_tour_add_member_full_name')" class="label-requires">
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('golf_tour_add_member_full_name')"
                                    rules="required|max:50"
                                    >
                                    <b-form-input
                                        :placeholder="$t('golf_tour_add_member_full_name')"
                                        :state="errors.length > 0 ? false : null"
                                        v-model="dataAddGroup.FullName"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('golf_tour_add_member_vga_code')">
                                <b-form-input
                                    :placeholder="$t('golf_tour_add_member_vga_code')"
                                    v-model="dataAddGroup.Vgacode"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('golf_tour_add_member_sex_code')" class="label-requires">
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('golf_tour_add_member_sex_code')"
                                    rules="required"
                                    >
                                        <v-select
                                            label="KeyValue"
                                            :class="(errors.length > 0) ? 'v-select-error' : ''"
                                            :state="errors.length > 0 ? false : null"
                                            :options="listSexCode"
                                            :reduce="item => item.KeyCode"
                                            :multiple="false"
                                            :placeholder="$t('golf_tour_add_member_sex_code')"
                                            v-model="dataAddGroup.SexCode"
                                        />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('golf_tour_add_member_birth_day')">
                                <date-range-picker
                                    v-model="dataAddGroup.BirthDay"
                                    :placeholder="$t('golf_tour_add_member_vga_code')"
                                    ref="picker"
                                    :single-date-picker="true"
                                    :ranges="false"
                                    opens="right"
                                    :time-picker="false"
                                    :time-picker-increment="1"
                                    :show-dropdowns="true"
                                    :auto-apply="true"
                                    :locale-data="{
                                        format: 'mm/dd/YYYY',
                                        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                        firstDay: 0
                                    }"
                                    />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('golf_tour_add_member_phone_number')" >
                                <b-form-input
                                        :placeholder="$t('golf_tour_add_member_phone_number')"
                                        v-model="dataAddGroup.PhoneNumber"
                                    />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group :label="$t('golf_tour_add_member_email')" >
                                <b-form-input
                                    :placeholder="$t('golf_tour_add_member_email')"
                                    v-model="dataAddGroup.Email"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('golf_tour_add_member_handicap_course')" class="label-requires">
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('golf_tour_add_member_handicap_course')"
                                    rules="required|max:50"
                                    >
                                    <b-form-input
                                        :placeholder="$t('golf_tour_add_member_handicap_course')"
                                        :state="errors.length > 0 ? false : null"
                                        v-model="dataAddGroup.HandicapCourse"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('golf_tour_add_member_handicap_index')" >
                                <b-form-input
                                    :placeholder="$t('golf_tour_add_member_handicap_index')"
                                    v-model="dataAddGroup.HandicapIndex"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card-body>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="saveGolfer"
                    v-if="data.type == 'ADD'"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                    v-else
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="updateGolfer"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                size="md"
                variant="none"
                class="float-right mr-1"
                @click="hideModal"
                >
                    {{ $t('golf_common_back') }}
                </b-button>
            
            </div>
        </template>
        </b-modal>
    </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'
import { commonServices } from '@/api/common-services'
import { tour } from '@/api/tour'
export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    props: ['tourId', 'data'],
    data() {
        return {
            dataAddGroup: {
                FullName: null,
                ClientId: null,
                Email: null,
                PhoneNumber: null,
                SexCode: null,
                BirthDay: null,
                Vgacode: null,
                Note: null,
                MemberId : null,
                HandicapIndex: null,
                HandicapCourse: null
            },
            currentLang: this.$i18n.locale,
            listSexCode: [],
            isOpen: false
        }
    },
    watch: {
        isOpen(value) {
            if(value) {
                this.dataAddGroup = this.data.data
            } else {
                this.clearData()
            }
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-add-member')
        },
        async saveGolfer() {
            this.$refs.addGroup.validate().then(async success => {
                if(success) {
                    const body = {
                        TournamentId: this.tourId,
                        TournamentMember: [
                            this.dataAddGroup
                        ]
                    }
                    await tour.api_TOUR_IGO01(body).then(res => {
                        this.showResToast(res)
                        if(res.Status == 200) {
                            this.hideModal()
                            this.$emit('event', { type: 'after-add-golfer' })
                        }
                    })
                }
            })
            
        },
        async updateGolfer() {
            this.$refs.addGroup.validate().then(async success => {
                if(success) {
                    const body = {
                        TournamentId: this.tourId,
                        TournamentMember: this.dataAddGroup
                        
                    }
                    await tour.api_TOUR_UGO01(body).then(res => {
                        this.showResToast(res)
                        if(res.Status == 200) {
                            this.hideModal()
                            this.$emit('event', { type: 'after-update-golfer' })
                        }
                    })
                }
            })
            
        },
        async saveAddGroup() {
            this.$refs.addGroup.validate().then(async success => {
                if(success) {
                   const body = {
                        Group: {
                            GroupName: this.dataAddGroup.GroupName,
                            Gender: this.dataAddGroup.Gender,
                            FromAge: Number(this.dataAddGroup.FromAge),
                            ToAge: Number(this.dataAddGroup.ToAge),
                            FromHadicapIndex: Number(this.dataAddGroup.FromHadicapIndex),
                            ToHadicapIndex: Number(this.dataAddGroup.ToHadicapIndex),
                            FromHadicapCourse: Number(this.dataAddGroup.FromHadicapCourse),
                            ToHadicapCourse: Number(this.dataAddGroup.ToHadicapCourse),
                            TournamentId: this.tourId
                        }
                   }
                   await tour.api_TOUR_IGR01(body).then(res => {
                        this.showResToast(res)
                        if(res.Status == 200) {
                            this.$emit('event', { type: 'after-create-group' })
                            this.hideModal()
                        }
                   })
                }
            })
        },
        async UpdateAddGroup() {
            this.$refs.addGroup.validate().then(async success => {
                if(success) {
                   const body = {
                        Group: {
                            Id: this.data.data.Id,
                            GroupName: this.dataAddGroup.GroupName,
                            Gender: this.dataAddGroup.Gender,
                            FromAge: Number(this.dataAddGroup.FromAge),
                            ToAge: Number(this.dataAddGroup.ToAge),
                            FromHadicapIndex: Number(this.dataAddGroup.FromHadicapIndex),
                            ToHadicapIndex: Number(this.dataAddGroup.ToHadicapIndex),
                            FromHadicapCourse: Number(this.dataAddGroup.FromHadicapCourse),
                            ToHadicapCourse: Number(this.dataAddGroup.ToHadicapCourse),
                            TournamentId: this.tourId
                        }
                   }
                   await tour.api_TOUR_UGR01(body).then(res => {
                        this.showResToast(res)
                        if(res.Status == 200) {
                            this.$emit('event', { type: 'after-create-group' })
                            this.hideModal()
                        }
                   })
                }
            })
        },
        clearData() {
            this. dataAddGroup = {
                FullName: null,
                ClientId: null,
                Email: null,
                PhoneNumber: null,
                SexCode: null,
                BirthDay: null,
                Vgacode: null,
                Note: null,
                MemberId : null,
                HandicapIndex: null,
                HandicapCourse: null
            }
        },
        async getListSexCode() {
            await commonServices
            .getCommon({ KeyGroup: 'SEX' })
            .then(res => {
            if (res) {
                this.listSexCode = res.Data.filter(x => x.LangId == this.currentLang)
            }
            })
        },
    },
    created() {
        this.getListSexCode()
    },
}
</script>
<style lang="scss">
 .banned-background-color-primary {
      background: rgba(17, 74, 159, 0.12);
}
</style>