import { tour } from '@/api/tour.js'
import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
  } from 'devextreme-vue/data-grid'
import 'devextreme/dist/css/dx.light.css'
import SidebarTournament from './SidebarTour/SidebarTournament.vue'
import { booking } from '@/api/booking'
export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRequiredRule,
        DxRangeRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport,
        SidebarTournament
    },
    data() {
        return {
            currentPage: 1,
            perPage: 20,
            dataGridRef: 'tablePackage',
            optionPerPage: [10, 20, 50],
            search: {
                StartDate: this.getUTC('start', 'day'),
                EndDate: this.getUTC('start', 'day'),
                TournamentCode: '',
                TournamentName: '',
                ScoringFormatId: []
            },
            listTour: [],
            heightTable: 400,
            windowHeight: 0,
            configTable: [
                {
                  caption: this.$t('golf_tour_table_tour_code'),
                  field: 'TournamentCode',
                  isShow: true,
                  alignment: 'center',
                  width: '10%',
                },
                {
                    caption: this.$t('golf_tour_table_tour_name'),
                    field: 'TournamentName',
                    isShow: true,
                    alignment: 'left',
                    width: '15%',
                },
                {
                    caption: this.$t('golf_tour_table_start_date'),
                    field: 'StartDate',
                    isShow: true,
                    alignment: 'center',
                    cellTemplate: 'StartDate',
                    width: '10%',
                },
                {
                    caption: this.$t('golf_tour_table_end_date'),
                    field: 'EndDate',
                    isShow: true,
                    alignment: 'center',
                    cellTemplate: 'EndDate',
                    width: '10%',
                },
                {
                    caption: this.$t('golf_tour_table_number_of_hole'),
                    field: 'Hole',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                },
                {
                    caption: this.$t('golf_tour_table_sponsor_name'),
                    field: 'SponsorName',
                    isShow: true,
                    alignment: 'left',
                    width: '15%',
                },
                {
                    caption: this.$t('golf_tour_table_course'),
                    field: 'Courses',
                    isShow: true,
                    alignment: 'center',
                    cellTemplate: 'Courses',
                    width: '15%',
                },
                {
                    caption: this.$t('golf_tour_table_status'),
                    field: 'Status',
                    isShow: true,
                    alignment: 'center',
                    cellTemplate: 'Status',
                    width: '15%',
                },
                {
                  caption: '',
                  field: 'action',
                  isShow: true,
                  cellTemplate: 'action',
                  alignment: 'center',
                  width: '5%',
                },
            ],
            isLoading: false,
            dataPropsDetailTour: {
                type: null,
                data: null
            },
            listFomularScore: null,
            listDefaultParOfHole: null,
            System36FormularOfPointDefault: null,
            StableFordFormularOfPointDefault: null,
            DoublePeoriaFormularOfPointDefault: null,
            listShowTotalPar: [],
            listShowParOfHole: [],
            listParOfHoleEnable4Edit: [],
            listSelectHole4Formular: [],
            listShowPointResult: [],
            listShowHDCResult: [],
        }
    },
    methods: {
        async getListTour(TourId) {
            this.isLoading = true
            await tour.api_TOUR_RTO01(this.search).then(res => {
                if(res.Status == 200) {
                    this.listTour = res.Data.Tournament
                    if(TourId) {
                        this.dataPropsDetailTour.type = 'DETAIL'
                        this.dataPropsDetailTour.data = res.Data.Tournament.find(x => x.Id == TourId)
                    }
                }
                this.isLoading = false
            })
        },
        openSideBarDetailTour() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-tournament')
        },
        async handleEvent(result) {
            if (result.type === 'after-add-tour') {
                this.getListTour(result.TourId)
              }
        },
        async deleteTour(data) {
            this.isLoading = true
            const body = {
                TournamentId: [data.Id],
                IsForce: true
            }
            await tour.api_TOUR_DTO01(body).then(res => {
                this.showResToast(res)
                if(res.Status == 200) {
                    this.getListTour()
                } else {
                    this.isLoading = false
                }
            })
        },
        viewDetailTour(item) {
            if (item.column && item.column.name != 'action' || force) {
                if(item.data.Id) {
                    this.dataPropsDetailTour.type = 'DETAIL'
                    this.dataPropsDetailTour.data = item.data
                    this.openSideBarDetailTour()
                }
              }
        },
        openSidebrAddTour() {
            this.dataPropsDetailTour = {
                type: 'ADD',
                data: null
            }
            this.openSideBarDetailTour()
        },
        async getConfigInfo_RCOM01() {
            const response = await booking.getConfigInfo({ KeyGroup: 'TOUR_CONFIG' })
            this.listFomularScore = response.Data?.find(x => x.KeyCode === 'SCORE_FOMULAR_LIST').KeyValue.split(',')
            this.listDefaultParOfHole = JSON.parse(response.Data?.find(x => x.KeyCode === 'COURSE_DEFAULT_PAR_OF_HOLE').KeyValue)
            this.System36FormularOfPointDefault = JSON.parse(response.Data?.find(x => x.KeyCode === 'SYSTEM_36_FORMULAR_OF_POINT').KeyValue)
            this.StableFordFormularOfPointDefault = JSON.parse(response.Data?.find(x => x.KeyCode === 'STABLEFORD_FORMULAR_OF_POINT').KeyValue)
            this.DoublePeoriaFormularOfPointDefault = JSON.parse(response.Data?.find(x => x.KeyCode === 'DOUBLE_PEORIA_FORMULAR_OF_POINT').KeyValue)
            JSON.parse(response.Data?.find(x => x.KeyCode === 'TOUR_GENERAL_CONFIG').KeyValue).forEach(x => {
                if(x.PAR_OF_HOLE_ENABLE_4_EDIT == 1) {
                    this.listParOfHoleEnable4Edit.push(x.FORMULAR)
                }
                if(x.SHOW_PAR_OF_HOLE == 1) {
                    this.listShowParOfHole.push(x.FORMULAR)
                }
                if(x.SHOW_TOTAL_PAR == 1) {
                    this.listShowTotalPar.push(x.FORMULAR)
                }
                if(x.SELECT_HOLE_4_FORMULAR == 1) {
                    this.listSelectHole4Formular.push(x.FORMULAR)
                }
                if(x.SHOW_POINT_IN_RESULT_TAB == 1) {
                    this.listShowPointResult.push(x.FORMULAR)
                }
                if(x.SHOW_HDC_IN_RESULT_TAB == 1) {
                    this.listShowHDCResult.push(x.FORMULAR)
                }
            })
        }
    },
    created() {
        this.getConfigInfo_RCOM01()
        this.getListTour()
    },
    watch: {
        windowHeight(value) {
              this.heightTable = value - 320
          }
    },
    mounted() {
        this.windowHeight = window.innerHeight
    }
}   