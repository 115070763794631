<template>
    <div>
    <b-modal
      id="modal-edit-point-member"
      v-model="isOpen"
      size="xl"
      centered
      hide-header
      :no-close-on-backdrop="true"
    >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <p class="mb-0 title-modal color-primary">
            {{ $t('golf_tour_edit_point_member') }}
          </p>
          <feather-icon
            icon="XIcon"
            size="24"
            class="cursor-pointer color-primary"
            @click="hideModal"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <b-row v-for="(score, index) in data" :key="index + 'rowMember'">
            <div class="d-flex justify-content-between">
                <b-form-group :label="index == 0 ? 'Họ và tên' : ''" class="m-1" style="width: 15%;" label-class="font-weight-bold">
                    {{ score.FullName }}
                </b-form-group>
                <b-form-group :label="index == 0 ? 'Mã VGA' : ''" class="m-1" style="width: 5%;" label-class="font-weight-bold">
                    {{ score.Vgacode }}
                </b-form-group>
                <div class="form-group m-1" v-for="(item, index1) in score.TournamentMemberScore" :key="index + 'member' + index1 + 'Point'">
                <label v-if="index == 0" class="d-flex justify-content-between">
                  <span class="font-weight-bold">Hole {{ item.Hole }}</span>
                  <span v-if="item.Hole == 9" class="font-weight-bold" style="font-size: 15px;color: red;">Total 9</span>
                  <span v-if="item.Hole == 18" class="font-weight-bold" style="font-size: 15px;color: red;">Total 18</span>
                  <span v-if="item.Hole == 27" class="font-weight-bold" style="font-size: 15px;color: red;">Total 27</span>
                  <span v-if="item.Hole == 36" class="font-weight-bold" style="font-size: 15px;color: red;">Total 36</span>
                </label>
                  <b-form-group>
                    <b-form-input
                      :id="index + 'member' + index1 + 'Point'"
                      style="padding: 5px !important;"
                      :tabindex="item.TabIndex"
                      :autofocus="index == 0 && index1 == 0"
                        v-model="item.Stoke"
                        @input="nextForcus(item.TabIndex, item.Stoke, item.Hole,  score)"
                        :type="'number'"
                    />
                    <b-form-input
                      v-if="item.Hole == 9"
                      style="padding: 5px !important;margin-left: 10px;font-weight: bold;"
                      v-model="score.Total9"
                      :disabled="true"
                    />
                    <b-form-input
                      v-if="item.Hole == 18"
                      style="padding: 5px !important;margin-left: 10px;font-weight: bold;"
                      v-model="score.Total18"
                      :disabled="true"
                    />
                    <b-form-input
                      v-if="item.Hole == 27"
                      style="padding: 5px !important;margin-left: 10px;font-weight: bold;"
                      v-model="score.Total27"
                      :disabled="true"
                    />
                    <b-form-input
                      v-if="item.Hole == 36"
                      style="padding: 5px !important;margin-left: 10px;font-weight: bold;"
                      v-model="score.Total36"
                      :disabled="true"
                    />
                  </b-form-group>
                </div>
               
                
            </div>
        </b-row>
        <!-- <DxDataGrid
                :ref="dataGridRef"
                :height="475"
                class="table-starter"
                :show-borders="false"
                :data-source="data"
                :show-column-lines="true"
                :show-row-lines="true"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                column-resizing-mode="widget"
                :column-auto-width="true"
                @row-updating="rowUpdating"
                @initialized="saveGridInstance"
                @key-down="onKeyDown"
                @focused-cell-changing="onFocusedCellChanging"
                @editor-preparing="onEditorPreparing"
              >
                <DxKeyboardNavigation
                  :edit-on-key-press="true"
                  :enter-key-action="'moveFocus'"
                  :enter-key-direction="'row'"
                />
                <DxColumn
                  v-for="(item, i) in configTable"
                  v-if="(item.field == 'StarterGroupCode') ? IsActiveMerge : item.isShow"
                  :key="i.field"
                  :caption="item.caption"
                  :data-field="item.field"
                  :data-type="item.type"
                  :group-index="(item.field == 'StarterGroupCode' && IsActiveMerge === true) ? 0 : -1"
                  :cell-template="(item.cellTemplate) ? item.cellTemplate : false"
                  :alignment="item.alignment"
                  :allow-editing="item.allowEditing"
                  :edit-cell-template="item.editCellTemplate"
                  :editor-options="item.editorOptions"
                  :header-cell-template="item.headerCellTemplate"
                  :allow-filtering="item.allowFiltering"
                  :cssClass="(item.field == 'Note' || item.field == 'NoteOfStarter') ? 'table-starter-wrapped-column' : ''"
                >
                  <DxColumn
                    v-for="(band, j) in item.bands"
                    v-if="band.isShow && item.bands.length > 0"
                    :key="band.field"
                    :caption="band.caption"
                    :data-field="band.field"
                    :data-type="band.type"
                    :cell-template="(band.cellTemplate) ? band.cellTemplate : false"
                    :header-cell-template="(band.headerCellTemplate) ? band.headerCellTemplate : false"
                    :alignment="band.alignment"
                    :allow-editing="item.allowEditing"
                    :edit-cell-template="item.editCellTemplate"
                    :cssClass="(item.field == 'Note' || item.field == 'NoteOfStarter') ? 'table-starter-wrapped-column' : ''"
                  />
                </DxColumn>
                <DxToolbar>
                  <DxItem
                    location="before"
                    template="beforeTemplate"
                  />
                  <DxItem
                    location="after"
                    template="afterTemplate"
                  />
                  <DxItem
                    widget="dxButton"
                    location="after"
                    :options="{width: 70}"
                    name="revertButton"
                  />
                  <DxItem
                    widget="dxButton"
                    location="after"
                    :options="{width: 70}"
                    name="saveButton"
                    cssClass='border-button'
                  />
                </DxToolbar>
                <template #beforeTemplate>
                  <div>
                    

                  </div>
                </template>
                <template #afterTemplate>
                  <div>
                    
                  </div>
                </template>

                <DxEditing
                  :allow-updating="true"
                  :allow-adding="false"
                  :allow-deleting="false"
                  :select-text-on-edit-start="false"
                  start-edit-action="dblClick"
                  mode="batch"
                >
                  <DxTexts
                    :edit-row="$t('golf_common_edit')"
                    :cancel-row-changes="$t('golf_common_cancel')"
                    :save-row-changes="$t('golf_common_save')"
                  />
                </DxEditing>
          </DxDataGrid> -->
      </b-card-body>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="none"
            size="md"
            class="float-right"
            @click="hideModal"
          >
          {{ $t('golf_common_back') }}
          </b-button>
          <b-button
            v-b-tooltip.hover
            title="Ctrl + S"
            variant="primary"
            size="md"
            class="float-right mr-1"
            @click="savePointMember"
          >
          {{ $t('golf_common_confirm') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :is-active="true"
      :all-child-component-closed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
      />
    </div>
</template>
<script>
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S, P, Key1,Key2,Key3,Key4,Key5,Key6,Key7,Key8,Key9,subKey1,subKey2,subKey3,subKey4,subKey5,subKey6,subKey7,subKey8,subKey9
} from '@/api/constant.js'
import {
  DxToolbar,
  DxItem,
  DxTexts,
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
  DxLoadPanel,
  DxKeyboardNavigation
} from 'devextreme-vue/data-grid'
import ShortKey from '@/views/modules/ultils/ShortKey'
import jquery from 'jquery'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import 'devextreme/dist/css/dx.light.css'
import { tour } from '@/api/tour'
const dataGridRef = 'dataGrid'
export default {
    components: {
      DxTexts,
      DxDataGrid,
      DxLoadPanel,
      DxToolbar,
      DxItem,
      DxColumn,
      DxEditing,
      DxFilterRow,
      DxHeaderFilter,
      DxGroupPanel,
      DxGrouping,
      DxScrolling,
      DxPaging,
      DxPager,
      DxSummary,
      DxLookup,
      DxTotalItem,
      DxGroupItem,
      DxMasterDetail,
      DxStringLengthRule,
      DxRangeRule,
      DxRequiredRule,
      DxValueFormat,
      DxColumnChooser,
      DxColumnFixing,
      DxSelection,
      DxExport,
      DxKeyboardNavigation,
      ShortKey
    },
    props: ['data', 'dataTour'],
    data() {
        return {
            dataGridRef,
            isOpen: false,
            dataGridInstance: null,
            ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S, P],
            allChildComponentClosed: [
               
            ],
            numberColumnInfo: 2,
            listShortCutKeyNumber: [Key1,Key2,Key3,Key4,Key5,Key6,Key7,Key8,Key9,subKey1,subKey2,subKey3,subKey4,subKey5,subKey6,subKey7,subKey8,subKey9]
        }
    },
    watch: {
        isOpen(val) {
          if(val) {
            console.log(this.data)
            // setTimeout(() => {
            //   this.dataGridInstance.editCell(0,2)
            // },500)
          }
          this.$emit('is-open', { ComponentId: 'modal-edit-point-member', IsActive: val})
        }
    },
    computed: {
      dataGrid() {
        return this.$refs[dataGridRef].instance
      },
      configTable() {
        const header = [
          {
            caption: 'Họ tên',
            field: 'FullName',
            isShow: true,
            alignment: 'center',
            allowEditing: false,
          },
          {
            caption: 'Mã VGA',
            field: 'Vgacode',
            isShow: true,
            alignment: 'center',
            allowEditing: false,
          }
        ]
        for(let i = 0; i < this.dataTour.Hole; i++) {
          header.push({
              caption: `H${i + 1}`,
              field: `Hole${i + 1}`,
              isShow: true,
              alignment: 'center',
              allowEditing: true,
            })
            // if(i == 8) {
            //   header.push({
            //     caption: `Total 9`,
            //     field: `Total9`,
            //     isShow: true,
            //     alignment: 'center',
            //     allowEditing: false,
            //   })
            // }
            // if(i == 17) {
            //   header.push({
            //     caption: `Total 18`,
            //     field: `Total18`,
            //     isShow: true,
            //     alignment: 'center',
            //     allowEditing: false,
            //   })
            // }
            // if(i == 26) {
            //   header.push({
            //     caption: `Total 27`,
            //     field: `Total27`,
            //     isShow: true,
            //     alignment: 'center',
            //     allowEditing: false,
            //   })
            // }
            // if(i == 35) {
            //   header.push({
            //     caption: `Total 36`,
            //     field: `Total36`,
            //     isShow: true,
            //     alignment: 'center',
            //     allowEditing: false,
            //   })
            // }
        }
          // this.data[0].TournamentMemberScore.forEach(y => {
          //   header.push({
          //     caption: `H${y.Hole}`,
          //     field: `Hole${y.Hole}`,
          //     isShow: true,
          //     alignment: 'center',
          //     allowEditing: true,
          //   })
          // })
        return header
      }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-edit-point-member')
        },
        rowUpdating() {

        },
        saveGridInstance(e) {
          this.dataGridInstance = e.component
        },
        async savePointMember() {
          // this.dataGridInstance.saveEditData() 
            const dataTemp = []
            this.data.forEach(member => {
              member.TournamentMemberScore.forEach(y => {
                dataTemp.push({
                    TournamentMemberId: member.Id,
                    CourseId: y.CourseId,
                    Hole: y.Hole,
                    Stoke: Number(y.Stoke)
                })
            })
            });
            const body = {
                TournamentScore: dataTemp
            }
            await tour.api_TOUR_UPA02(body).then(res => {
                this.showResToast(res)
                if(res.Status == 200) {
                    this.hideModal()
                    this.$emit('event', { type: 'after-edit-point' })
                }
            })
        },
        onTriggeredEventHandler(payload) {
            if (payload.evt.ctrlKey && payload.key === S) {
              this.savePointMember()
            }
        },
        onKeyDown(e) {
          const focusedRowIndex = e.component.option("focusedRowIndex");
          const focusedColumnIndex = e.component.option("focusedColumnIndex");
          if (this.listShortCutKeyNumber.find(x => x == e.event.keyCode)) {
            console.log(focusedRowIndex,focusedColumnIndex)
            if(focusedColumnIndex >= this.numberColumnInfo) {
              this.dataGridInstance.editCell(focusedRowIndex,focusedColumnIndex + 1)
            }
          }
        },
        onFocusedCellChanging(e) {
          // console.log(392, e)
        },
        onEditorPreparing(e) {
          console.log(407, e)
        },
        changeInputPoint(id, point) {
          console.log(document.getElementById(id).tabIndex)
        },
        nextForcus(tabindex, point, hole, score) {
          score.Total9 = 0
          score.Total18 = 0
          score.Total27 = 0
          score.Total36 = 0
          score.TournamentMemberScore.forEach(x => {
            if(x.Hole <= 9) {
              score.Total9 += Number(x.Stoke)
            }
            if(x.Hole <= 18) {
              score.Total18 += Number(x.Stoke)
            }
            if(x.Hole <= 27) {
              score.Total27 += Number(x.Stoke)
            }
            if(x.Hole <= 36) {
              score.Total36 += Number(x.Stoke)
            }
          })
          if(point > 1) {
            if(jquery('[tabindex="' + (tabindex+1)  + '"]')[0]) {
              jquery('[tabindex="' + (tabindex+1)  + '"]')[0].focus()
            }
          }
        },
    },
}
</script>
<style lang="scss">
    #modal-edit-point-member {
    .form-group >div {
      display: flex;
      .form-control:disabled {
        background-color: yellow;
      }
    }
  .block-header {
    .title-modal {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }

  .color-primary {
    color: #114A9F;
  }

  .w-50 {
    width: 65px !important;
  }

  .color-warning {
    color: #FF9F43;
  }

  .content-body-modal {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .fullname {
    width: 160px;
  }

  .block {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      padding: 0;
    }

    p {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }
}
</style>