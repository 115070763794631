/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
    ROOT,
    API_TOUR_RTO01,
    API_TOUR_ITO01,
    API_TOUR_DTO01,
    API_TOUR_IGO01,
    API_TOUR_UGO01,
    API_TOUR_DGO01,
    API_TOUR_IGR01,
    API_TOUR_UTO01,
    API_TOUR_RGO01,
    API_TOUR_RGR01,
    API_TOUR_DGR01,
    API_TOUR_UGR01,
    API_TOUR_UGR04,
    API_TOUR_UGR02,
    API_TOUR_UGR03,
    API_TOUR_ITE01,
    API_TOUR_RTE01,
    API_TOUR_UTE02,
    API_TOUR_DTE01,
    API_TOUR_UPA02,
    API_TOUR_UPA03,
    API_TOUR_UTE01,
    API_TOUR_IBK01,
    API_TOUR_UGO02,
    API_TOUR_UBK10
  } from '@/api/constant'
  import { handleResponse } from '@/api/handle-response'
  import { requestOptions } from '@/api/request-options'
  function api_TOUR_IBK01(data) {
    return fetch(ROOT + API_TOUR_IBK01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_UGO02(data) {
    return fetch(ROOT + API_TOUR_UGO02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_UBK10(data) {
    return fetch(ROOT + API_TOUR_UBK10, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_DGR01(data) {
    return fetch(ROOT + API_TOUR_DGR01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_UTE01(data) {
    return fetch(ROOT + API_TOUR_UTE01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_UPA03(data) {
    return fetch(ROOT + API_TOUR_UPA03, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_RTE01(data) {
    return fetch(ROOT + API_TOUR_RTE01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_DTE01(data) {
    return fetch(ROOT + API_TOUR_DTE01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_UTE02(data) {
    return fetch(ROOT + API_TOUR_UTE02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_UGR01(data) {
    return fetch(ROOT + API_TOUR_UGR01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_UGR04(data) {
    return fetch(ROOT + API_TOUR_UGR04, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_UGR03(data) {
    return fetch(ROOT + API_TOUR_UGR03, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_UGR02(data) {
    return fetch(ROOT + API_TOUR_UGR02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_RTO01(data) {
    return fetch(ROOT + API_TOUR_RTO01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_RGR01(data) {
    return fetch(ROOT + API_TOUR_RGR01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_RGO01(data) {
    return fetch(ROOT + API_TOUR_RGO01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_IGR01(data) {
    return fetch(ROOT + API_TOUR_IGR01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_IGO01(data) {
    return fetch(ROOT + API_TOUR_IGO01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_UGO01(data) {
    return fetch(ROOT + API_TOUR_UGO01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_DGO01(data) {
    return fetch(ROOT + API_TOUR_DGO01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_ITO01(data) {
    return fetch(ROOT + API_TOUR_ITO01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_UTO01(data) {
    return fetch(ROOT + API_TOUR_UTO01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_DTO01(data) {
    return fetch(ROOT + API_TOUR_DTO01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_ITE01(data) {
    return fetch(ROOT + API_TOUR_ITE01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function api_TOUR_UPA02(data) {
    return fetch(ROOT + API_TOUR_UPA02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  export const tour = {
    api_TOUR_RTO01,
    api_TOUR_ITO01,
    api_TOUR_DTO01,
    api_TOUR_IGO01,
    api_TOUR_IGR01,
    api_TOUR_UTO01,
    api_TOUR_RGO01,
    api_TOUR_RGR01,
    api_TOUR_DGR01,
    api_TOUR_UGR01,
    api_TOUR_UGR04,
    api_TOUR_UGR02,
    api_TOUR_UGR03,
    api_TOUR_ITE01,
    api_TOUR_UGO01,
    api_TOUR_DGO01,
    api_TOUR_RTE01,
    api_TOUR_UTE02,
    api_TOUR_DTE01,
    api_TOUR_UPA02,
    api_TOUR_UPA03,
    api_TOUR_UTE01,
    api_TOUR_IBK01,
    api_TOUR_UGO02,
    api_TOUR_UBK10
  }
  